import React, { useState, useEffect } from "react";
import "./signup.scss";
import signupSideImage from "../../assets/images/signupSideImage.png";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { postMethod } from "../../utils/services/api";
import urls from "../../utils/services/apiUrl.json";
import { useHistory } from "react-router-dom";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { setStoreId, setUserSession } from "../../utils/common";
import LoadingOverlay from "react-loading-overlay";
import { toast } from "react-toastify";

function SignUp() {
    const [state, setState] = useState({
        storename: "",
        username: "",
        password: "",
    });
    const [storeName, setStoreName] = useState("");
    const history = useHistory();
    const [loader, setLoader] = useState(false);

    const toSentenceCase = (str) => {
        if (!str) return '';
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleEmailChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [name]: value.trim(),
        }));
    };

    useEffect(() => {
        checkStoreName();
    }, [state.storename, storeName]);

    useEffect(() => { }, [storeName]);

    const checkStoreName = async () => {
        if (state.storename && state.storename.length > 0) {
              let body = {
                    storename: state.storename,
              };
            await postMethod(urls.verifyStoreName, body).then((response) => {
                if (response.status === 200) {
                    setStoreName(true);
                } else {
                    setStoreName(false);
                }
            }).catch((error) => {
                console.log("Error in APi calling", error);
            });
        }
    };

    const handleSubmit = async (event) => {
        setLoader(true);
        let body = {
            storename: state.storename,
            password: btoa(state.password),
            username: state.username,
            role: "vendor",
        };

        if (state.storename !== "" && state.username !== "" && state.password !== "") {
            await postMethod(urls.signup, body).then((response) => {
                if (response.status === 200) {
                    setLoader(false);
                    setUserSession(response.data.key);
                    setStoreId(response.data.store_id);
                    history.push("/aboutStore");
                } else if (response.status === 400) {
                    setLoader(false);
                    toast.error(toSentenceCase(response.data.detail));
                } else {
                    setLoader(false);
                    toast.error("Invalid username or password");
                }
                setLoader(false);
            }).catch((error) => {
                setLoader(false);
            });
        } else {
        setLoader(false);
        }
    };

    const gotoBack = async () => {
        history.push("/");
    };

    return (
        <LoadingOverlay active={loader} spinner text="Processing...">
            <div className="container-fluid p-0 m-0">
                <div className="outerView">
                    <div className="row">
                        <div className="col-6">
                            <div className="signupBusinessDeal container">
                                <div>
                                    <h2 className="headingMain">Getting online, made easy</h2>
                                    <h5 className="headingSmall">
                                        Launch your local store online and reach thousands of new
                                        customers
                                    </h5>
                                </div>
                                <img className="shopezImagesignup img-fluid" src={signupSideImage} alt={""} />
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="signupForm container">
                                <div className='back-signup' onClick={() => gotoBack()}>
                                    <i class="fas fa-2x fa-arrow-left back_arrow_login" ></i>
                                </div>
                                <div className="signupLogoView">
                                    <img className="signupLogo" src={logo} alt={""} />{" "}
                                </div>
                                <div className="formSection">
                                    <ValidatorForm>
                                        <div className="company">
                                            <TextValidator
                                                errorMessages={["Please Enter StoreName"]}
                                                validators={["minStringLength:5", "maxStringLength:15"]}
                                                onChange={handleChange}
                                                value={state.storename}
                                                name="storename"
                                                label="Store Name"
                                                variant="filled"
                                            />
                                            {storeName && state.storename && state.storename.length > 0 ? (
                                                <div style={{ color: "green", paddingTop: "10px" }}>
                                                    Store name is available
                                                </div>
                                            ) : !storeName && state.storename && state.storename.length > 0 ? (
                                                <div style={{ color: "Red", paddingTop: "10px" }}>
                                                    Store name is not available
                                                </div>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <div className="userName">
                                            <TextValidator
                                                errorMessages={[
                                                    "Please Enter UserName",
                                                    "Please Enter Valid Email",
                                                ]}
                                                validators={["minStringLength:5", "isEmail"]}
                                                onChange={handleEmailChange}
                                                value={state.username}
                                                name="username"
                                                label="Username"
                                                variant="filled"
                                            />
                                        </div>
                                        <div className="password">
                                            <TextValidator
                                                errorMessages={["Please Enter Password"]}
                                                validators={["minStringLength:5", "maxStringLength:15"]}
                                                onChange={handleChange}
                                                value={state.password}
                                                name="password"
                                                type="password"
                                                label="Password"
                                                variant="filled"
                                            />
                                        </div>
                                        <button onClick={handleSubmit} className="signupButton w-100 text-truncate" name="login">
                                            {" "}
                                            Create your Shopez store{" "}
                                        </button>
                                        <div className="doNotHaveAccount">
                                            Have an account?{" "}
                                            <b>
                                                {" "}
                                                <Link to="/login">Sign In</Link>
                                            </b>
                                        </div>
                                    </ValidatorForm>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LoadingOverlay>
    );
}
export default SignUp;
