import React, { useState } from "react";
import Modal from "react-modal";

function OnHoldModal(props) {
    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    })

    const customStylesModal = {
        content: {
            top: "40%",
            left: "50%",
            transform: screenSize.width <= 800 ? 'translate(-20%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            // padding: "15px",
            width: screenSize.width <= 800 ? "40vw" : "38vw",
            height: screenSize.width <= 800 ? "15vh" : "15vh",
        },
    };
    return (
        <div>
            <Modal isOpen={props.isOnHold} style={customStylesModal} overlayClassName="myoverlay">
                <div className="mt-4 d-flex justify-content-center align-items-center">
                    <div className="row">
                        <div className="col-12 modalonhold text-center">
                            Your store has been temporarily suspended. Please contact Shopez administrator at <span style={{color:"#1B98F2",textDecoration:"underline"}}>support@myshopez.com.</span>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default OnHoldModal
