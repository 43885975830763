import React, { useState, useEffect } from "react";
import { getMethod } from "../../../utils/services/api";
import urls from "../../../utils/services/apiUrl.json";
import CollectionDB from "../../../assets/images/collection.png";
import SpinnerLoader from "../../../components/loader/spinnerLoader";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Pagination from '@mui/material/Pagination';
import OnHoldModal from "../../../components/OnHoldModal/onHoldModal";
import ExportIcon from '../../../assets/images/export-csv.png'
import Modal from "react-modal";
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Button from "../../../components/button/button";
import { toast } from "react-toastify";
import format from 'date-fns/format';
import { getStoreId } from "../../../utils/common";

function Transaction(props) {

    const itemsPerPage = 20;
    const history = useHistory();
    const [listOfSuppliers, setListOfSuppliers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [modalMe, setModalMe] = useState(false)
    const [exportModal, setExportModal] = useState(false)
    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    })
    const [exportStartDate, setExportStartDate] = useState("")
    const [exportEndDate, setExportEndDate] = useState("")
    const [exportShowStartDate, setExportShowStartDate] = useState("")
    const [exportShowEndDate, setExportShowEndDate] = useState("")
    const [showExportDate, setShowExportDate] = useState(false)

    const allStyless = {
        content: {
            top: "30%",
            left: "80%",
            transform: screenSize.width <= 1100 ? 'translate(-60%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: screenSize.width <= 1100 ? "35vw" : "25vw",
            height: "23vh",
        },
    };

    useEffect(() => {
        supplierList();
    }, []);

    const supplierList = async () => {
        setLoading(true);
        await getMethod(urls.getBankHistory).then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count / itemsPerPage))
                setListOfSuppliers(response.data.results.data);
                setLoading(false);
            }
        });
        setLoading(false);
    };

    const getSteps = async () => {
        await getMethod(urls.getCustomerStripeId).then((response) => {
            if (response.status === 200) {
                setModalMe(response.data.on_hold_check_flag)
            }
        });
    };

    const handleClick = (id) => {
        history.push(`/orders/details/${id}`);
    }

    const handlePageChange = async (event, value) => {
        setLoading(true);
        getSteps()
        setPage(value)
        await getMethod(urls.getBankHistory + "?&page=" + value).then((response) => {
            if (response.status === 200) {
                setCount(Math.ceil(response.data.count / itemsPerPage))
                setListOfSuppliers(response.data.results.data);
                setLoading(false);
            }
        });
        setLoading(false);
    }

    const showSupplierList = () => {
        if (loading) {
            return <SpinnerLoader />;
        } else {
            return (
                <>
                    <div className="col-12">
                        {count > 1 &&
                            <div className="shopPagination mt-4">
                                <OnHoldModal isOnHold={modalMe} />
                                <Pagination
                                    count={count}
                                    page={page}
                                    showFirstButton
                                    showLastButton
                                    onChange={(event, value) => handlePageChange(event, value)}
                                />
                            </div>
                        }
                    </div>

                    <div className="row ml-3 mr-3 pl-3 pr-3 mt-4">
                        <div className="col-12">
                            <div className="row" style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>
                                <div className="col-1 f-600">S.No</div>
                                <div className="col-1 f-600">Date</div>
                                <div className="col-2 f-600">Order Number</div>
                                <div className="col-2 f-600">Name/Email</div>
                                <div className="col-2 f-600">Shipping Charges</div>
                                <div className="col-2 f-600">Total Tax</div>
                                <div className="col-2 f-600">Order Total</div>
                            </div>
                        </div>
                        <div className="col-12">
                            {listOfSuppliers && listOfSuppliers.length > 0 ? listOfSuppliers.map((item, index) => {
                                return (
                                    <>
                                        <div className="row mt-3 pt-3 pb-3" style={{ background: "#F6F6F6", display: "flex", justifyContent: "center", textAlign: "center" }}>
                                            <div className="col-1" style={{ alignItems: "center" }}>
                                                {(page - 1) * itemsPerPage + index + 1}
                                            </div>
                                            <div className="col-1" style={{ alignItems: "center" }}>
                                                {moment(item?.date_placed).format("YYYY/MM/DD")}
                                            </div>

                                            <div className="col-2" style={{ alignItems: "center" }}>
                                                {item?.number}
                                            </div>

                                            <div className="col-2 d" style={{ alignItems: "center" }}>
                                                {item?.user_detail?.first_name + " " + item?.user_detail?.last_name}{","} {item?.user_detail?.email}
                                            </div>

                                            <div className="col-2" style={{ alignItems: "center" }}>
                                                ${item?.orderSummary?.[0]?.shippingCharge?.toFixed(2)}
                                            </div>

                                            <div className="col-2" style={{ alignItems: "center" }}>
                                                ${item?.orderSummary?.[0]?.totalTax?.toFixed(2)}
                                            </div>

                                            <div className="col-2" style={{ alignItems: "center" }}>
                                                ${item?.orderSummary?.[1]?.orderTotal?.toFixed(2)}
                                            </div>

                                            {/* <div className="col-2" style={{ alignItems: "center" }}>
                                                {item?.number}
                                            </div> */}


                                        </div>
                                    </>
                                );
                            }) :
                                <div className="row ft-14 mt-3 pt-3 pb-3" style={{ background: "#F6F6F6", textAlign: "center", alignItems: "center", height: "75px" }}>
                                    <div className="col-12 " >No transaction data found.</div>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="col-12">
                        {count > 1 &&
                            <div className="shopPagination mt-4 mb-5">
                                <Pagination
                                    count={count}
                                    page={page}
                                    showFirstButton
                                    showLastButton
                                    onChange={(event, value) => handlePageChange(event, value)}
                                />
                            </div>
                        }
                    </div>
                </>
            )
        }

    };

    const openExportData = () => {
        setExportModal(true)
    }

    const closeExport = () => {
        setExportModal(false)
    }

    const handleExportData = async () => {
        try {
            const response = await getMethod(urls.exportData + "?start_date=" + format(exportStartDate, 'yyyy-MM-dd') + "&end_date=" + format(exportEndDate, 'yyyy-MM-dd') + "&store_id=" + getStoreId() + "/");

            if (response.status === 200 || response.status === 201) {
                const data = response.data?.response;
                setExportModal(false)
                setExportShowStartDate(response?.data?.start_date)
                setExportShowEndDate(response?.data?.end_date)
                setShowExportDate(true)

                if (!Array.isArray(data) || data.length === 0) {
                    console.error("No data available to export");
                    return;
                }

                const headers = Object.keys(data[0]).join(",");

                const rows = data.map(row =>
                    Object.keys(row)
                        .map(key => row[key])
                        .join(",")
                ).join("\n");

                const csvContent = headers + "\n" + rows;
                const blob = new Blob([csvContent], { type: "text/csv" });
                const url = URL.createObjectURL(blob);

                const link = document.createElement("a");
                link.href = url;
                link.download = "export sells report.csv";
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(url);
                toast.success('CSV file exported and downloaded successfully')
            }
        } catch (error) {
            console.error("Error exporting data:", error);
        }
    }

    const handleChangeDate = (date) => {
        setExportStartDate(date);
    }

    const handleEndChangeDate = (date) => {
        setExportEndDate(date)
    }


    return (
        <>
            <div className={props.menu ? "screenLeftMargin w-100" : "screenLeftMarginResponse w-100"}>
                <div className="d-flex justify-content-between ml-3 mr-3 mt-4">
                    <div>
                        <h1 className="heading-title">Transactions Details</h1>
                    </div>
                    <div className="d-flex">
                        {/* <div>
                            <h4 className="dashboard-date mt-1 mr-3">{showExportDate ? `${moment(exportShowStartDate).format('MMM D, YYYY')} - ${moment(exportShowEndDate).format('MMM D, YYYY')}` : formattedDate}</h4>
                        </div> */}
                        <div className="ml-2" onClick={() => openExportData()} style={{ cursor: "pointer" }}>
                            <img src={ExportIcon} />
                            <span className="filter-text ml-1">Export Sales Data</span>
                        </div>
                    </div>
                </div>
                {showSupplierList()}
            </div>
            <Modal isOpen={exportModal} style={allStyless} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="d-flex justify-content-between">
                    <div className="filter-text">Export Sales Data</div>
                    <div>
                        <button onClick={() => closeExport()} className="filterCloseIcon">
                            <CloseIcon style={{ fontSize: "18px" }} />
                        </button>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-6">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                variant="outlined"
                                format="yyyy-MM-dd"
                                margin="normal"
                                id="date-picker-inline"
                                label="Start Date"
                                className={exportStartDate !== "" ? "input-datepicker selectedDate" : "input-datepicker"}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                InputProps={{ disableUnderline: true }}
                                value={exportStartDate ? format(exportStartDate, 'yyyy MM dd') : ''}
                                onChange={(e) => handleChangeDate(e)}
                                error={false}
                                helperText={null}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-6">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                variant="outlined"
                                format="yyyy-MM-dd"
                                margin="normal"
                                id="date-picker-inline"
                                label="End Date"
                                className={exportEndDate !== "" ? "input-datepicker selectedDate" : "input-datepicker"}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                InputProps={{ disableUnderline: true }}
                                value={exportEndDate ? format(exportEndDate, 'yyyy MM dd') : ''}
                                onChange={(e) => handleEndChangeDate(e)}
                                error={false}
                                helperText={null}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 mt-3" style={{ textAlign: "center" }}>
                        <Button className="btn btn-primary" name="Export" onClick={() => handleExportData()}></Button>
                    </div>
                </div>
            </Modal>
        </>
    );
}
export default Transaction;
