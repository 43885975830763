import React, { useState, useEffect } from "react";
import Select from "@material-ui/core/Select";
import { Checkbox, FormControl, InputAdornment, makeStyles, MenuItem, TextField } from "@material-ui/core";
import Button from "../../../../../components/button/button";
import "./shipping.scss";
import { getStoreId, getProductId, getProductEditableFlag, setShippingEditedFlag, getShippingEditedFlag } from "../../../../../utils/common";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { getProductDetails, postMethodWithToken, putMethodWithToken } from "../../../../../utils/services/api";
import urls from "../../../../../utils/services/apiUrl.json";
import SpinnerLoader from "../../../../../components/loader/spinnerLoader";
import { toast } from "react-toastify";
import { getMethod } from "../../../../../utils/services/api";
import closeIcon from "../../../../../assets/images/closeIcon.svg";
import OnHoldModal from "../../../../../components/OnHoldModal/onHoldModal";

const checkShippingInfo = (shippingData, otherCharges) => {

    const emptyKeys = [
        'product_length',
        'product_width',
        'product_height',
        'product_weight',
    ];

    const emptyKeysErrors = [
        'length',
        'width',
        'height',
        'weight',
    ];

    for (let i = 0; i < emptyKeys.length; i++) {
        if (!shippingData[emptyKeys[i]]) {
            return `Please fill in ${emptyKeysErrors[i]}`;
        }
    }

    /*if(add_ons !== undefined && add_ons !== '') {
        if(add_ons.charges && (add_ons.recycle_deposit === undefined || add_ons.recycle_deposit === '')) {
            return `Please fill in Other Charges Name`;
        }

        if((add_ons.charges === undefined || add_ons.charges === '') && add_ons.recycle_deposit) {
            return `Please fill in Other Charges Value`;
        }
    }*/

    return '';
}

function Shipping({ shippingData, prevShippingData }) {
    const [checkIfVariantClicked, setCheckIfVariantClicked] = useState(false);

    const [options, setOptions] = useState([
        { value: "in", label: "in" },
        { value: "cm", label: "cm" },
        { value: "m", label: "m" },
        { value: "ft", label: "ft" },
    ]);

    const [weightOptions, setWeightOptions] = useState([
        { value: "lbs", label: "lbs" },
        { value: "kg", label: "kg" },
    ]);

    const [weightOption, setWeightOption] = useState("lbs");
    const [selectedOption, setSelectedOption] = useState("in");
    //const [add_ons, setAdd_ons] = useState("");
    const [otherCharges, setOtherCharges] = useState([{ recycle_deposit: '', charges: '' }]);
    const [loader, setLoader] = useState(false);
    const [modalMe, setModalMe] = useState(false)

    const [state, setState] = useState({
        product_length: "",
        product_width: "",
        product_height: "",
        product_weight: "",
        eligible_for_ship: true,
        shop_pick_up: false,
        distanceUnit: selectedOption,
        massUnit: weightOption,
        //eligible_for_delivery: true,
    });

    const toSentenceCase = (str) => {
        if (!str) return '';
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    useEffect(() => {
        getSteps()
        if (prevShippingData) {
            setState(prevShippingData.state)
            setOtherCharges(prevShippingData.otherCharges)
            setLoader(true);
        } else {
            getShippingDetails();
        }
    }, []);

    const getSteps = async () => {
        await getMethod(urls.getCustomerStripeId).then((response) => {
            if (response.status === 200) {
                setModalMe(response.data.on_hold_check_flag)
            }
        });
    };

    useEffect(() => {
        let backData = {
            state: state,
            otherCharges: otherCharges,
            is_submit: false,
        }
        shippingData(backData)
    }, [state, otherCharges]);

    const getShippingDetails = async () => {
        if (getProductId() !== null && getProductEditableFlag() !== 'added') {
            let storeId = getStoreId();
            let productId = getProductId();
            let moduleName = "shipping";
            await getProductDetails(storeId, productId, moduleName).then((shippingDetails) => {
                if (shippingDetails.status === 200 && shippingDetails.data.response.dimensions !== null) {
                    setShippingEditedFlag('editable')
                    setState((prevState) => ({
                        ...prevState,
                        ["id"]: shippingDetails.data.response.dimensions.id,
                        ["product_length"]: shippingDetails.data.response.dimensions.product_length,
                        ["product_width"]: shippingDetails.data.response.dimensions.product_width,
                        ["product_height"]: shippingDetails.data.response.dimensions.product_height,
                        ["product_weight"]: shippingDetails.data.response.dimensions.product_weight,
                        ["eligible_for_ship"]: shippingDetails.data.response.dimensions.eligible_for_ship,
                        ["shop_pick_up"]: shippingDetails.data.response.dimensions.shop_pick_up,
                        ["distanceUnit"]: shippingDetails.data.response.dimensions.distanceUnit ? shippingDetails.data.response.dimensions.distanceUnit : selectedOption,
                        ["massUnit"]: shippingDetails.data.response.dimensions.massUnit ? shippingDetails.data.response.dimensions.massUnit : weightOption,
                        //["eligible_for_delivery"]: shippingDetails.data.dimensions.eligible_for_delivery,
                    }));

                    if (shippingDetails.data.response.productaddons.length > 0) {
                        setOtherCharges(shippingDetails.data.response.productaddons);
                    }

                    if (shippingDetails.data.response.dimensions.distanceUnit) {
                        setSelectedOption(shippingDetails.data.response.dimensions.distanceUnit)
                    }

                    if (shippingDetails.data.response.dimensions.massUnit) {
                        setWeightOption(shippingDetails.data.response.dimensions.massUnit)
                    }

                }
            }).catch((error) => {
                console.log("API error", error);
            });
        } else {
            setShippingEditedFlag('added')
        }
        setLoader(true);
    };

    const onHandleShipping = (event) => {
        setState((prevState) => ({
            ...prevState,
            ["eligible_for_ship"]: event.target.checked,
            // ["shop_pick_up"]: !event.target.checked,
        }));
    };
    const onHandleShippingPickUP = (event) => {
        setState((prevState) => ({
            ...prevState,
            ["shop_pick_up"]: event.target.checked,
            // ["eligible_for_ship"]: !event.target.checked,
        }));
    };

    /*const onHandleDelivery = (event) => {
        setState((prevState) => ({
            ...prevState,
            ["eligible_for_delivery"]: event.target.checked,
        }));
    };*/

    function handleOtherCharges() {
        setOtherCharges([...otherCharges, { recycle_deposit: '', charges: '' }]);
    }

    function handleRemoveOtherCharge(i) {
        const values = [...otherCharges];
        values.splice(i, 1);
        setOtherCharges(values);
    }

    function handleChangeOtherName(e, index) {
        const { name, value } = e.target;
        const list = [...new Set(otherCharges)];
        list[index][name] = value;
        setOtherCharges(list);
    }

    function handleChangeOtherValue(event, index) {
        const { name, value } = event.target;

        if (value.length <= 5) {
            const list = [...new Set(otherCharges)];
            list[index][name] = value;
            setOtherCharges(list);
        }
    }

    const handleChangeOtherDecimalChange = (event, index) => {
        const { name, value } = event.target;

        if (value.includes('.')) {
            let splitValue = value.split('.');

            let decimalValue = '';
            if (splitValue[1].length === 1) {
                decimalValue = value + 0;
            } else {
                decimalValue = value;
            }

            const list = [...new Set(otherCharges)];
            list[index][name] = decimalValue;
            setOtherCharges(list);
        } else {
            const list = [...new Set(otherCharges)];
            list[index][name] = parseInt(value).toFixed(2);
            setOtherCharges(list);
        }
    }

    const handleChangeData = (e) => {
        const { name, value } = e.target;

        setState((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleUnitChange = (event) => {
        let prLength = '';
        let prWidth = '';
        let prHeight = '';

        if (selectedOption === 'm') {
            prLength = state.product_length * 39.37007874;
            prWidth = state.product_width * 39.37007874;
            prHeight = state.product_height * 39.37007874;
        } else if (selectedOption === 'ft') {
            prLength = state.product_length * 12;
            prWidth = state.product_width * 12;
            prHeight = state.product_height * 12;
        } else if (selectedOption === 'cm') {
            prLength = state.product_length * 0.3937007874;
            prWidth = state.product_width * 0.3937007874;
            prHeight = state.product_height * 0.3937007874;
        } else {
            prLength = state.product_length * 1;
            prWidth = state.product_width * 1;
            prHeight = state.product_height * 1;
        }

        if (event.target.value === 'm') {
            prLength = prLength / 39.37007874;
            prWidth = prWidth / 39.37007874;
            prHeight = prHeight / 39.37007874;
        } else if (event.target.value === 'ft') {
            prLength = prLength / 12;
            prWidth = prWidth / 12;
            prHeight = prHeight / 12;
        } else if (event.target.value === 'cm') {
            prLength = prLength / 0.3937007874;
            prWidth = prWidth / 0.3937007874;
            prHeight = prHeight / 0.3937007874;
        }

        setSelectedOption(event.target.value);

        setState((prevState) => ({
            ...prevState,
            ["product_length"]: prLength ? prLength.toFixed(3) : '',
            ["product_width"]: prWidth ? prWidth.toFixed(3) : '',
            ["product_height"]: prHeight ? prHeight.toFixed(3) : '',
            ['distanceUnit']: event.target.value,
        }));
    };

    const weightOptionChange = (event) => {
        let prWeight = '';

        if (weightOption === 'kg') {
            prWeight = state.product_weight * 2.2046244202
        } else {
            prWeight = state.product_weight * 1
        }

        if (event.target.value === 'kg') {
            prWeight = prWeight / 2.2046244202
        }

        setWeightOption(event.target.value);

        setState((prevState) => ({
            ...prevState,
            ["product_weight"]: prWeight ? prWeight.toFixed(3) : '',
            ['massUnit']: event.target.value,
        }));
    };

    /*const handleChangeNumber = (e) => {
        setAdd_ons((prevState) => ({
            ...prevState,
            charges: e.target.value,
        }));
    };*/

    /*const onSubmitEditToAddDecimal = (e) => {
        if (e.target.value.includes(".")) {
            setAdd_ons((prevState) => ({
                ...prevState,
                charges: e.target.value,
            }));
        } else {
            let valueOfField = parseInt(e.target.value);
            let requiredString = valueOfField.toFixed(2);
            setAdd_ons((prevState) => ({
                ...prevState,
                charges: requiredString,
            }));
        }
    };*/

    const onSubmitShipping = async (value) => {
        const shippingError = checkShippingInfo(state, otherCharges);

        if (shippingError) {
            toast.error(shippingError)
        } else {
            let storeId = getStoreId();
            let product_id = getProductId();

            let shippingBody = {
                store_id: storeId,
                product_id: product_id,
                dimensions: state,
                add_ons: otherCharges,
            };

            if (product_id && getShippingEditedFlag() === 'editable') {
                await putMethodWithToken(urls.aboutStore + storeId + "/product/" + product_id + "/?module=" + value, shippingBody).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        toast.success(toSentenceCase(response.data.success));
                        let backData = {
                            state: state,
                            otherCharges: otherCharges,
                            is_submit: true,
                        }
                        shippingData(backData)
                    } else {
                        toast.error(toSentenceCase(response.data.error));
                    }
                }).catch((error) => {

                });

            } else {
                await postMethodWithToken(urls.addProduct + value, shippingBody).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        setShippingEditedFlag('editable')
                        toast.success(toSentenceCase(response.data.success));
                        let backData = {
                            state: state,
                            otherCharges: otherCharges,
                            is_submit: true,
                        }

                        shippingData(backData)
                    } else {
                        toast.error(toSentenceCase(response.data.error));
                    }
                }).catch((error) => {

                });
            }
        }
    }

    /*const useStyles = makeStyles((theme) => ({
        categoryControl: {
            marginTop: "0.5vw",
            width: "41vw",
            backgroundColor: "#fff",
        },
        lengthUnit: {
            marginTop: "0vw",
            borderRadius: "0px !important",
            "&:hover": {
                borderRadius: "0px !important",
                opacity: 1,
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
            },
            "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
            },
        },
        categoryControlOpen: {
            marginTop: "0.5vw",
            width: "38vw",
            backgroundColor: "#fff",
        },
        subCategoryControlOpen: {
            marginTop: "0.5vw",
            width: "38vw",
            backgroundColor: "#fff",
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }));

    const classes = useStyles();*/

    return (
        <div className="container-fluid">
            {!loader ? (
                <SpinnerLoader />
            ) : (

                <div className="row pt-3">
                    <div className="col-6">
                        <div className="row">
                            <div className="col-12 p-0">
                                <div className="h5-label">Shipping</div>
                                <div className="shippingDescription pt-2">
                                    Enter the weight & dimensions of the product including the packaging material for shipping
                                </div>
                            </div>
                        </div>
                        <ValidatorForm className="row">
                            <div className="col-xl-4 col-lg-6 pt-3">
                                <div className="row">
                                    <div className="col-8 pl-0 pr-0">
                                        <TextValidator
                                            required
                                            validators={[
                                                "minStringLength:1",
                                                "maxStringLength:10",
                                                "required",
                                            ]}
                                            type="number"
                                            onChange={handleChangeData}
                                            value={state.product_length}
                                            className="br-left-top-bottom"
                                            label="Length"
                                            name="product_length"
                                            variant="outlined"
                                        />
                                    </div>
                                    <div className="col-4 pl-0">
                                        <FormControl variant="outlined" className="br-right-top-bottom">
                                            <Select
                                                labelId="length-unit"
                                                id="length-unit-dropdown"
                                                value={selectedOption}
                                                onChange={handleUnitChange}
                                            >
                                                {options.map((item) => {
                                                    return (
                                                        <MenuItem value={item.value}> {item.label} </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 pt-3">
                                <div className="row">
                                    <div className="col-8 pl-0 pr-0">
                                        <TextValidator
                                            required
                                            validators={[
                                                "minStringLength:1",
                                                "maxStringLength:10",
                                                "required",
                                            ]}
                                            type="number"
                                            onChange={handleChangeData}
                                            value={state.product_width}
                                            className="br-left-top-bottom"
                                            label="Width"
                                            name="product_width"
                                            variant="outlined"
                                        />
                                    </div>
                                    <div className="col-4 pl-0">
                                        <FormControl variant="outlined" className="br-right-top-bottom">
                                            <Select
                                                labelId="width-unit"
                                                id="width-unit-dropdown"
                                                value={selectedOption}
                                                onChange={handleUnitChange}
                                            >
                                                {options.map((item) => {
                                                    return (
                                                        <MenuItem value={item.value}> {item.label} </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 pt-3">
                                <div className="row">
                                    <div className="col-8 pl-0 pr-0">
                                        <TextValidator
                                            required
                                            validators={[
                                                "minStringLength:1",
                                                "maxStringLength:10",
                                                "required",
                                            ]}
                                            type="number"
                                            onChange={handleChangeData}
                                            value={state.product_height}
                                            className="br-left-top-bottom"
                                            label="Height"
                                            name="product_height"
                                            variant="outlined"
                                        />
                                    </div>
                                    <div className="col-4 pl-0" >
                                        <FormControl variant="outlined" className="br-right-top-bottom">
                                            <Select
                                                labelId="height-unit"
                                                id="height-unit-dropdown"
                                                value={selectedOption}
                                                onChange={handleUnitChange}
                                            >
                                                {options.map((item) => {
                                                    return (
                                                        <MenuItem value={item.value}> {item.label} </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 pt-3">
                                <div className="row">
                                    <div className="col-8 pl-0 pr-0">
                                        <TextValidator
                                            required
                                            validators={[
                                                "minStringLength:1",
                                                "maxStringLength:10",
                                                "required",
                                            ]}
                                            type="number"
                                            onChange={handleChangeData}
                                            value={state.product_weight}
                                            className="br-left-top-bottom"
                                            label="Weight"
                                            name="product_weight"
                                            variant="outlined"
                                        />
                                    </div>
                                    <div className="col-4 pl-0" >
                                        <FormControl variant="outlined" className="br-right-top-bottom">
                                            <Select
                                                labelId="length-unit"
                                                id="weight-unit-dropdown"
                                                value={weightOption}
                                                onChange={weightOptionChange}
                                            >
                                                {weightOptions.map((item) => {
                                                    return (
                                                        <MenuItem value={item.value}> {item.label} </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-3 p-0">

                                <Checkbox
                                    checked={state.eligible_for_ship}
                                    onChange={onHandleShipping}
                                    color="primary"
                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                />
                                This product is eligible for Shipping
                            </div>
                            <div className="col-12 mt-3 p-0">
                                <Checkbox
                                    checked={state.shop_pick_up}
                                    onChange={onHandleShippingPickUP}
                                    color="primary"
                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                />
                                This product is eligible for In-Store Pickup
                            </div>
                        </ValidatorForm>
                    </div>

                    <div className="col-6">
                        <div className="row">
                            <div className="col-12">
                                <div className="h5-label">Other Charges</div>
                                <div className="shippingDescription pt-2">
                                    Enter other charges applicable to a product.
                                </div>
                            </div>
                        </div>

                        {otherCharges.map((item, i) => {
                            return (
                                <div className="row pt-3">
                                    <div className="col-5">
                                        <TextField
                                            value={item.recycle_deposit || ""}
                                            onChange={(e) => handleChangeOtherName(e, i)}
                                            label="Other Charges"
                                            name="recycle_deposit"
                                            variant="outlined"
                                        />
                                    </div>
                                    <div className="col-6 pr-0">
                                        <TextField
                                            type="number"
                                            onChange={(e) => handleChangeOtherValue(e, i)}
                                            onBlur={(e) => handleChangeOtherDecimalChange(e, i)}
                                            value={item.charges || ''}
                                            InputProps={{
                                                inputProps: { min: 0 },
                                                startAdornment: (
                                                    <InputAdornment position="start">$</InputAdornment>
                                                ),
                                            }}
                                            label="Value"
                                            name="charges"
                                            className="br-left-top-bottom"
                                            variant="outlined"
                                        />
                                    </div>
                                    <div className="col-1 p-0">
                                        <div className="removeOtherCharge" onClick={() => handleRemoveOtherCharge(i)}>
                                            <img className="closeIcon" src={closeIcon} alt="" />
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                        <div className="row pt-3">
                            <div className="col-12">
                                <div>
                                    <Button name="Add more Other Charges" onClick={() => handleOtherCharges()} className="btn btn-cancle w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <OnHoldModal isOnHold={modalMe} />

                    <div className="col-12 mt-3 d-flex justify-content-end">
                        <Button name="Save" onClick={() => onSubmitShipping("shipping")} className="btn btn-primary mb-5" />
                    </div>
                </div>
            )}
        </div>
    );
}
export default Shipping;
