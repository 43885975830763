import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import { getMethod, getMethodWithStripe, getMethodWithPlatfrom, postMethodWithPlatform, postMethodWithToken } from "../../../utils/services/api";
import urls from "../../../utils/services/apiUrl.json";
import { getStoreId } from "../../../utils/common";
import { baseUrl } from "../../../utils/env.json";
import CustomizedTables from "../../../components/table/table";
import Button from "../../../components/button/button";
import PDF from "../../../assets/images/pdfHolder.svg";
import USA from "../../../assets/images/USA.svg";
import CAN from "../../../assets/images/canada.png";
import "./review.scss";
import DefaultImage from "../../../assets/images/ShopezDefult.svg"
import DOC from "../../../assets/images/doc.png"

function Review(props) {
    console.log(props)
    const history = useHistory();

    const [storeSubscription, setStoreSubscription] = useState("");
    const [storeUserDetails, setStoreUserDetails] = useState("");
    const [storeTypeOf, setStoreTypeOf] = useState([]);
    const [storeCategory, setStoreCategory] = useState([]);
    const [storeProductList, setStoreProductList] = useState([]);
    const [storeAnnualRevenue, setStoreAnnualRevenue] = useState("");
    const [storeBusinessAddress, setStoreBusinessAddress] = useState("");
    const [storeFinicialInfo, setStoreFinicialInfo] = useState("");
    const [storeName, setStoreName] = useState("");
    const [storeLogo, setStoreLogo] = useState("");
    const [storeFront, setStoreFront] = useState("");
    const [storeBasket, setStoreBasket] = useState([]);

    const toSentenceCase = (str) => {
        if (!str) return '';
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    const [bankState, setBankState] = useState({
        bank_full_name: '',
        bank_account_type: '',
        bank_routing_number: '',
        bank_institution_number: '',
        bank_account_number: '',
    });

    const [cardState, setCardState] = useState({
        card_number: '',
        card_month: '',
        card_year: '',
        card_cvv: '',
    });

    const getPrice = (price) => {
        return price.toFixed(2);
    };

    const checkFileExtension = (item) => {
        var fileExtension = item.file.split(".").pop();
        return fileExtension === "pdf";
    };

    const checkFileExtensionDoc = (item) => {
        var fileExtension = item.file.split(".").pop();
        return fileExtension === "doc";
    }

    const getExpiryMonth = (month) => {
        let Length = String(month);
        if (Length.length == 1) {
            return "0" + month;
        } else {
            return month;
        }
    };

    const getExpiryYear = (year) => {
        let Year = String(year);
        return Year.substring(2);
    };

    const getPhoneFormat = (phone) => {
        const str = phone;
        const format = str.replace(/[^a-z\d\s]+/gi, "");
        const removeSpace = format.split(" ").join("");
        return "+" + removeSpace;
    };

    useEffect(() => {
        getCurrentStatus();
        getBaskets();
        getStoreDetails();
    }, []);

    window.$progressStatus = "100%";

    const getCurrentStatus = async () => {
        await getMethod(urls.getUserDetailsMe).then((response) => {
            if (response?.status === 200) {
                window.$step = response?.data?.signup_step;
                if (response?.data?.signup_step <= 4) {
                    localStorage.setItem("progressStatus", response?.data?.signup_step);
                }
            }
        }).catch((error) => {
            console.log("error", error);
        });
    };

    const getBaskets = async () => {
        await getMethodWithPlatfrom(urls.getBasket, "merchant").then((response) => {
            if (response.status === 200) {
                setStoreBasket(response?.data);
            }
        }).catch((error) => {
            console.log("error", error);
        });
    };

    const getStoreDetails = async () => {
        let storeId = getStoreId();

        await getMethod(urls.vendorOnboarding + storeId).then((response) => {
            if (response.status === 200) {
                setStoreUserDetails(response?.data?.user);
                setStoreName(response?.data?.name);
                setStoreSubscription(response?.data?.subscription)
                setStoreTypeOf(response?.data?.type_of_store)
                setStoreCategory(response?.data?.category)
                setStoreAnnualRevenue(response?.data?.current_annual_revenue)
                setStoreBusinessAddress(response?.data?.business_address)
                setStoreProductList(response?.data?.product);
                setStoreFinicialInfo(response?.data?.financial_info);
                setStoreLogo(response?.data?.business_address?.store_logo);
                setStoreFront(response?.data?.business_address?.store_icon);

                if (response?.data?.financial_info && response?.data?.financial_info?.bankAccountID) {
                    getMethodWithStripe(urls.postStripeCustomers + '/' + response?.data.financial_info?.customerStripeID + '/sources/' + response?.data.financial_info.bankAccountID, response.data.financial_info.billing_address.country).then((response) => {
                        setBankState((prevState) => ({
                            ...prevState,
                            ["bank_full_name"]: response.data.account_holder_name,
                            ["bank_account_type"]: response.data.account_holder_type,
                            ["bank_routing_number"]: response.data.routing_number,
                            ["bank_institution_number"]: response.data.metadata.institution_number,
                            ["bank_account_number"]: '********' + response.data.last4,
                        }));
                    });
                } else if (response.data.financial_info && response.data.financial_info.canada_bank_detail) {
                    setBankState((prevState) => ({
                        ...prevState,
                        ["bank_full_name"]: response.data.financial_info.canada_bank_detail.full_name,
                        ["bank_account_type"]: response.data.financial_info.canada_bank_detail.account_type,
                        ["bank_routing_number"]: response.data.financial_info.canada_bank_detail.routing_number,
                        ["bank_institution_number"]: response.data.financial_info.canada_bank_detail.institution_number,
                        ["bank_account_number"]: '********' + response.data.financial_info.canada_bank_detail.account_number.substr(-4),
                    }));
                }

                if (response.data.financial_info && response.data.financial_info.customerCardId) {
                    getMethodWithStripe(urls.postStripeCustomers + '/' + response.data.financial_info.customerStripeID + '/sources/' + response.data.financial_info.customerCardId, response.data.financial_info.billing_address.country).then((response) => {
                        setCardState((prevState) => ({
                            ...prevState,
                            ["card_number"]: '**** **** **** ' + response.data.last4,
                            ["card_month"]: response.data.exp_month,
                            ["card_year"]: response.data.exp_year,
                            ["card_cvv"]: '***',
                        }));
                    });
                }
            }
        }).catch((error) => {
            console.log("error", error);
        });
    }

    console.log(storeSubscription)
    const onSubmitHandle = async () => {

        if (storeFinicialInfo && storeFinicialInfo.billing_address && storeBasket.length > 0) {
            let countryValue = '';
            if (storeFinicialInfo.billing_address.country === 'USA') {
                countryValue = 'US'
            } else if (storeFinicialInfo.billing_address.country === 'CAN') {
                countryValue = 'CA';
            } else {
                countryValue = storeFinicialInfo.billing_address.country;
            }

            let body = {
                basket: baseUrl + urls.getBasket + storeBasket[0].id + "/",
                guest_email: storeUserDetails.email,
                total: storeBasket[0].total_excl_tax,
                shipping_charge: {
                    currency: "USD",
                    excl_tax: "0.0",
                    tax: "0.0",
                },
                shipping_method_code: "no-shipping-required",
                shipping_address: {
                    country: baseUrl + urls.country + countryValue + "/",
                    first_name: storeFinicialInfo.billing_address.first_name,
                    last_name: storeFinicialInfo.billing_address.last_name,
                    line1: storeFinicialInfo.billing_address.address,
                    line2: "",
                    line3: storeFinicialInfo.billing_address.state,
                    line4: storeFinicialInfo.billing_address.city,
                    notes: "",
                    phone_number: '',
                    postcode: storeFinicialInfo.billing_address.postcode,
                    state: storeFinicialInfo.billing_address.state,
                    title: "",
                },
                billing_address: {
                    country: baseUrl + urls.country + countryValue + "/",
                    first_name: storeFinicialInfo.billing_address.first_name,
                    last_name: storeFinicialInfo.billing_address.last_name,
                    line1: storeFinicialInfo.billing_address.address,
                    line2: "",
                    line3: storeFinicialInfo.billing_address.state,
                    line4: storeFinicialInfo.billing_address.city,
                    notes: "",
                    phone_number: '',
                    postcode: storeFinicialInfo.billing_address.postcode,
                    state: storeFinicialInfo.billing_address.state,
                    title: "",
                },
            };

            await postMethodWithPlatform(urls.checkout, body, "merchant").then((response) => {
                if (response.status === 200) {
                    goToHome();
                } else {
                    toast.error(toSentenceCase(response.error));
                }
            }).catch((error) => {
                console.log("error", error);
            });
        } else {
            toast.error('Please fill all details.')
        }
    }

    const goToHome = async () => {
        let body = {
            onboardstatus: "True",
        };

        await postMethodWithToken(urls.verifyOnboardStatus, body).then((response) => {
            if (response.status === 200) {
                history.push("/home");
                window.location.reload();
            } else {
                toast.error(toSentenceCase(response.error));
            }
        }).catch((error) => {
            console.log("error", error);
        });
    };

    console.log(storeLogo);

    return (
        <div className={props.menu ? "screenLeftMargin w-100" : "screenLeftMarginResponse w-100"} style={{ marginTop: props.onBoardingStatus === false ? "-45px" : "-20px" }}>
            <div className="row ml-3 mr-3 mobileResponsive">
                <div className="col-12">
                    <h1 className="heading-title">Review Store Details</h1>
                </div>

                {/* <div className="col-12 mt-3">
                    {storeSubscription !== null ? (
                        <div className="selectedPlanDetails">
                            {storeSubscription.product_name} @ CA$
                            {getPrice(Number(storeSubscription.price_incl_tax) * 1.26)}
                            per month
                        </div>
                    ) : (
                        <div className="selectedPlanDetails"> @ CA$ per month</div>
                    )}
                </div> */}
                {/* <div className="col-12 mt-2 d-flex">
                    <div className="planDescription">3 Stores</div>
                    <div className="planDescription">5 Banners</div>
                    <div className="planDescription">100 Deals</div>
                    <div className="planDescription">Unlimited Products</div>
                </div> */}
            </div>

            <div className="row ml-3 mr-3 mobileResponsive">
                <div className="col-lg-6 col-md-12">
                    <div className="row mt-3">
                        <div className="col-2">
                            <div className="row">
                                {/* <div className="col-12">
                                    <div className="tellUsLabel">Store Icon</div>
                                </div> */}
                                <div className="col-12 mt-2">
                                    <div className="tellUsLabel">Store Icon</div>
                                    <div className="storeImageBlock">
                                        <div>
                                            <img
                                                className="imgTagBlock"
                                                src={storeLogo?.[0]?.file ? storeLogo?.[0]?.file : DefaultImage}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    {console.log(storeLogo)}
                                </div>
                            </div>
                        </div>
                        <div className="col-10">
                            <div className="row">
                                {/* <div className="col-12">
                                    <div className="tellUsLabel">Store Front</div>
                                </div> */}
                                <div className="col-12 mt-2">
                                    <div className="tellUsLabel">Store Front</div>
                                    <div className="storeImageBlock">
                                        <div>
                                            <img
                                                className="imgTagBlock"
                                                src={storeFront?.[0]?.file ? storeFront?.[0]?.file : DefaultImage}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-12">
                            <div className="h6-label">
                                What describes you the best as a store?
                            </div>
                        </div>

                        <div className="col-12 mt-3">
                            <div className="row">
                                {storeTypeOf.length > 0 && storeTypeOf.map((item) => {
                                    return (
                                        <div className="col-2">
                                            <div className="frame storeType">
                                                <span className="helper"></span>
                                                <div className="selectedGrid">
                                                </div>
                                                <img src={item?.active_image} />
                                                <div className="cardNameSelected">
                                                    {item?.name}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-12">
                            <div className="h6-label">
                                How would you categorize your store?
                            </div>
                        </div>

                        <div className="col-12 mt-3">
                            <div className="row">
                                {storeCategory.length > 0 && storeCategory.map((item) => {
                                    return (
                                        <div className="col-2">
                                            <div className="frame storeType">
                                                <span className="helper"></span>
                                                <div className="selectedGrid">
                                                </div>
                                                <img src={item?.active_image} />
                                                <div className="cardNameSelected">
                                                    {item?.name}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>

                    {storeAnnualRevenue.cost !== "" &&
                        <div className="row mt-3">
                            <div className="col-12">
                                <div className="h6-label">
                                    What is your current annual revenue?
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="revenue-review mt-2">{storeAnnualRevenue && storeAnnualRevenue?.cost}</div>
                            </div>

                            {/* <div className="col-12 mt-2">
                            <TextField
                                className="w-100"
                                value={storeAnnualRevenue && storeAnnualRevenue.cost}
                                name="current_annual_revenue"
                                label="Anual Revenue"
                                variant="outlined"
                            />
                        </div> */}
                        </div>
                    }
                </div>

                <div className="col-lg-6 col-md-12">
                    <div className="row mt-3">
                        <div className="col-12">
                            <h5 className="h5-label">Your business address</h5>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-6">
                            <TextField
                                className="w-100"
                                value={storeName}
                                name="store_name"
                                label="Store Name"
                                variant="outlined"
                            />
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-6">
                            <TextField
                                className="w-100"
                                value={storeBusinessAddress && storeBusinessAddress?.first_name}
                                name="first_name"
                                label="First Name"
                                variant="outlined"
                            />
                        </div>
                        <div className="col-6">
                            <TextField
                                className="w-100"
                                value={storeBusinessAddress && storeBusinessAddress?.last_name}
                                name="last_name"
                                label="Last Name"
                                variant="outlined"
                            />
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-8">
                            <TextField
                                className="w-100"
                                value={storeBusinessAddress && storeBusinessAddress?.address}
                                name="address"
                                label="Address"
                                variant="outlined"
                            />
                        </div>
                        <div className="col-4">
                            <TextField
                                className="w-100"
                                value={storeBusinessAddress && storeBusinessAddress?.unit}
                                name="unit"
                                label="Unit"
                                variant="outlined"
                            />
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-6">
                            <TextField
                                className="w-100"
                                value={storeBusinessAddress && storeBusinessAddress?.country}
                                name="country"
                                label="Country"
                                variant="outlined"
                            />
                        </div>

                        <div className="col-6">
                            <TextField
                                className="w-100"
                                value={storeBusinessAddress && storeBusinessAddress?.state}
                                name="state"
                                label={storeBusinessAddress && storeBusinessAddress?.country === "USA" ? "State" : "Province"}
                                variant="outlined"
                            />
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-6">
                            <TextField
                                className="w-100"
                                value={storeBusinessAddress && storeBusinessAddress?.city}
                                name="city"
                                label="City"
                                variant="outlined"
                            />
                        </div>

                        <div className="col-6">
                            <TextField
                                className="w-100"
                                value={storeBusinessAddress && storeBusinessAddress?.postal_code}
                                name="postal_code"
                                label={storeBusinessAddress && storeBusinessAddress?.country === "USA" ? "ZIP Code" : "Postal Code"}
                                variant="outlined"
                            />
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-6">
                            <TextField
                                className="w-100"
                                value={storeBusinessAddress && storeBusinessAddress?.contact_number}
                                name="contact_number"
                                label="Phone Number"
                                variant="outlined"
                            />
                        </div>

                        <div className="col-6">
                            <TextField
                                className="w-100"
                                value={storeBusinessAddress && storeBusinessAddress?.website}
                                name="website"
                                label="Website"
                                variant="outlined"
                            />
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-12">
                            <h5 className="h5-label">Your business credentials</h5>
                            {/* <div className="muiDescripationInfo">
                                Your business registration document (such as a incorporation certificate) and a
                                government issued ID is required to verify and protect identity of your business.
                                You may launch your store now but it will only be activated after proper verification
                                of your business credentials.
                            </div> */}
                        </div>

                        <div className="col-12 mt-2 d-flex">
                            {storeBusinessAddress && storeBusinessAddress.business_doc && storeBusinessAddress.business_doc.map((item) => {
                                return (
                                    <div className="storeImageBlock d-flex mr-3">
                                        <div className="ml-2 mr-3">
                                            {checkFileExtension(item) ? (
                                                <>
                                                    <a href={item.file} target="_blank">
                                                        <img className="imgTagBlock" src={PDF} alt="" />
                                                    </a>
                                                    <div className="image-word">{item.file ? item.file.split('/').pop() : ""}</div>
                                                </>
                                            ) :
                                                checkFileExtensionDoc(item) ?
                                                    <>
                                                        <a href={item.file} target="_blank">
                                                            <img className="imgTagBlock" src={DOC} alt="" />
                                                        </a>
                                                        <div className="image-word">{item.file ? item.file.split('/').pop() : ""}</div>
                                                    </>
                                                    :
                                                    (
                                                        <>
                                                            <a href={item.file} target="_blank">
                                                                <img className="imgTagBlock" src={item?.file} alt="" />
                                                            </a>
                                                            <div className="image-word">{item.file ? item.file.split('/').pop() : ""}</div>
                                                        </>
                                                    )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>

            {storeProductList.length > 0 &&
                <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                    <div className="col-12">
                        <h3 className="h3-label">Store Listing</h3>
                        <div className="muiDescripationInfo">
                            Stock your online store with as many products as you can. Let your
                            perspective customers discover what you have in store.
                        </div>
                    </div>

                    <div className="col-12">
                        <CustomizedTables
                            className="listSectionAddproduct ml-4"
                            storeData={storeProductList}
                        />
                    </div>
                </div>
            }

            <div className="row ml-3 mr-3 mobileResponsive" style={{ marginTop: "80px" }}>
                <div className="col-12">
                    <h3 className="h3-label">Financial Information</h3>
                    {/* <div className="muiDescripationInfo">
                        Shopez allows buyers to choose from variety of payment options protected by SSL encryption and security.
                    </div> */}
                </div>
            </div>

            <div className="row ml-3 mr-3 mt-3 mobileResponsive">
                <div className="col-lg-6 col-md-12">
                    <div className="row">
                        <div className="col-12">
                            <h5 className="h5-label">Accepted mode of payment</h5>
                            {/* <div className="muiDescripationInfo">
                                Based on the store preference, Shopez customers will have an option to pay
                                online and/or in-store, refer to
                                <span className="blueText"> Shopez Payment Procesing.</span>
                            </div> */}
                        </div>
                    </div>

                    {storeFinicialInfo && storeFinicialInfo.payment_mode &&
                        <div className="row mt-2">
                            <div className="col-12">
                                <div className="frame storeType">
                                    <span className="helper"></span>
                                    <div className="selectedGrid">
                                    </div>

                                    <img src={storeFinicialInfo.payment_mode.active_image} />

                                    <div className="cardNameSelected">
                                        {storeFinicialInfo?.payment_mode?.name}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <div className="row mt-3">
                        <div className="col-12">
                            <h5 className="h5-label">Payment Information</h5>
                            {/* <div className="muiDescripationInfo">
                                Your bank account to deposit funds from the sale proceeds (if
                                applicable). All your banking information is protected via SSL
                                encryption and security.
                            </div> */}
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-12">
                            <h6 className="h6-label">Bank Details</h6>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-12">
                            <div className="bank">
                                <img src={storeFinicialInfo && storeFinicialInfo.billing_address && storeFinicialInfo?.billing_address?.country === 'USA' ? USA : CAN} />
                                {storeFinicialInfo && storeFinicialInfo.billing_address && storeFinicialInfo?.billing_address?.country}
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-12">
                            <TextField
                                className="w-100"
                                value={bankState?.bank_full_name}
                                name="full_name"
                                label="Full name on account"
                                variant="outlined"
                            />
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-12">
                            <TextField
                                className="w-100"
                                value={bankState?.bank_account_type}
                                name="bank_account_type"
                                label="Account Type"
                                variant="outlined"
                            />
                        </div>
                    </div>

                    <div className="row mt-3">
                        {storeFinicialInfo && storeFinicialInfo.billing_address && storeFinicialInfo?.billing_address?.country === 'USA' ?
                            <div className="col-12">
                                <TextField
                                    className="w-100"
                                    value={bankState?.bank_routing_number}
                                    name="bank_routing_number"
                                    label="Routing No."
                                    variant="outlined"
                                />
                            </div>
                            :
                            <>
                                <div className="col-6">
                                    <TextField
                                        className="w-100"
                                        value={bankState?.bank_institution_number}
                                        name="bank_institution_number"
                                        label="Institution Number"
                                        variant="outlined"
                                    />
                                </div>
                                <div className="col-6">
                                    <TextField
                                        className="w-100"
                                        value={bankState?.bank_routing_number}
                                        name="bank_routing_number"
                                        label="Transit Number"
                                        variant="outlined"
                                    />
                                </div>
                            </>
                        }
                    </div>

                    <div className="row mt-3">
                        <div className="col-12">
                            <TextField
                                className="w-100"
                                value={bankState?.bank_account_number}
                                name="bank_account_number"
                                label="Account No."
                                variant="outlined"
                            />
                        </div>
                    </div>

                </div>
            </div>

            <div className="col-12 ml-2" style={{ marginTop: "80px" }}>
                <h1 className="heading-title ml-2">Plans</h1>
            </div>
            <div className="row ml-2 mr-3 mobileResponsive" >
                <div className="col-lg-6 col-md-12 mt-4">
                    <h5 className="h5-label ml-2">Selected Plan</h5>
                    <div className="card cardDesign mt-3" style={{ width: "30%", height: "74%" }} data-ribbon={storeSubscription && storeSubscription?.product_name}>
                        <div className="card-header cardHeading text-center">
                            <div className="plan_heading">
                                ${storeSubscription && storeSubscription?.price_excl_tax}
                            </div>
                            <div class="cardText">Per Month</div>
                        </div>

                        <div class="card-body cardBody text-center">
                            <div class="btn btn-primary w-100 pt-3 pb-3 mt-4 mb-4" disabled={true} style={{ cursor: "auto" }}>
                                Selected
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-12 mt-4">
                    <div className="row">
                        <div className="col-12">
                            <h5 className="h5-label">Billing Information</h5>

                            {/* <div className="muiDescripationInfo">
                                Your credit/debit card will not be charged to launch and manage
                                your store. Only <span className="blueText">Premium Services </span> will be
                                charged to this card after your consent
                            </div> */}
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-12">
                            <h6 className="h6-label">Credit Card</h6>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-12">
                            <TextField
                                value={cardState?.card_number}
                                name="card_number"
                                label="Number"
                                variant="outlined"
                            />
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-6">
                            <TextField
                                value={getExpiryMonth(cardState?.card_month) + '/' + getExpiryYear(cardState?.card_year)}
                                name="expiry"
                                label="Expiry (MM/YY)"
                                variant="outlined"
                            />
                        </div>
                        <div className="col-6">
                            <TextField
                                value={cardState?.card_cvv}
                                name="card_cvv"
                                label="CVC"
                                variant="outlined"
                                disabled={true}
                            />
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-12">
                            <h6 className="h6-label">Billing Address</h6>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-6">
                            <TextField
                                className="w-100"
                                value={storeFinicialInfo && storeFinicialInfo.billing_address && storeFinicialInfo?.billing_address?.first_name}
                                name="first_name"
                                label="First Name"
                                variant="outlined"
                            />
                        </div>
                        <div className="col-6">
                            <TextField
                                className="w-100"
                                value={storeFinicialInfo && storeFinicialInfo.billing_address && storeFinicialInfo?.billing_address?.last_name}
                                name="last_name"
                                label="Last Name"
                                variant="outlined"
                            />
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-8">
                            <TextField
                                className="w-100"
                                value={storeFinicialInfo && storeFinicialInfo.billing_address && storeFinicialInfo?.billing_address?.address}
                                name="Address"
                                label="Address"
                                variant="outlined"
                            />
                        </div>
                        <div className="col-4">
                            <TextField
                                className="w-100"
                                value={storeFinicialInfo && storeFinicialInfo.billing_address && storeFinicialInfo?.billing_address?.unit}
                                name="unit"
                                label="Unit"
                                variant="outlined"
                            />
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-6">
                            <TextField
                                className="w-100"
                                value={storeFinicialInfo && storeFinicialInfo.billing_address && storeFinicialInfo?.billing_address?.country}
                                name="country"
                                label="Country"
                                variant="outlined"
                            />
                        </div>
                        <div className="col-6">
                            <TextField
                                className="w-100"
                                value={storeFinicialInfo && storeFinicialInfo.billing_address && storeFinicialInfo?.billing_address?.state}
                                name="state"
                                label={storeFinicialInfo && storeFinicialInfo.billing_address && storeFinicialInfo?.billing_address?.country === "USA" ? "State" : "Province"}
                                variant="outlined"
                            />
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-6">
                            <TextField
                                className="w-100"
                                value={storeFinicialInfo && storeFinicialInfo.billing_address && storeFinicialInfo.billing_address.city}
                                name="city"
                                label="City"
                                variant="outlined"
                            />
                        </div>
                        <div className="col-6">
                            <TextField
                                className="w-100"
                                value={storeFinicialInfo && storeFinicialInfo.billing_address && storeFinicialInfo?.billing_address?.postcode}
                                name="postal_code"
                                label={storeFinicialInfo && storeFinicialInfo.billing_address && storeFinicialInfo?.billing_address?.country === "USA" ? "Postal Code" : "ZIP Code"}
                                variant="outlined"
                            />
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-6">
                            <TextField
                                className="w-100"
                                value={storeFinicialInfo && storeFinicialInfo.billing_address && storeFinicialInfo?.billing_address?.contact_number}
                                name="contact_number"
                                label="Phone Number"
                                variant="outlined"
                            />
                        </div>
                        <div className="col-6">
                            <TextField
                                className="w-100"
                                value={storeFinicialInfo && storeFinicialInfo.billing_address && storeFinicialInfo?.billing_address?.website}
                                name="website"
                                label="Website"
                                variant="outlined"
                            />
                        </div>
                    </div>

                    {/* <div className="row mt-3">
                    <div className="col-12 d-flex justify-content-end">
                        <Button onClick={() => onSubmitHandle()} name="Finish" className="btn btn-primary mb-5" />
                    </div>
                </div> */}
                </div>

                <div className="col-12 d-flex justify-content-end" style={{ marginTop: "150px", marginBottom: "60px" }}>
                    <Button onClick={() => onSubmitHandle()} name="Launch Store" className="btn btn-primary mb-5" />
                </div>

            </div>

        </div>
    );
}
export default Review;