import React, { useState, useEffect } from "react";
import "./plans.scss";
import { useHistory, useLocation } from "react-router-dom";
import { getStoreId } from "../../../utils/common";
import { getMethod, postMethodWithPlatform } from "../../../utils/services/api";
import urls from "../../../utils/services/apiUrl.json";
import { baseUrl } from "../../../utils/env.json";
import { toast } from "react-toastify";
import Skeleton from '@mui/material/Skeleton';
import OnHoldModal from "../../../components/OnHoldModal/onHoldModal";

const skeletonArray = [1, 2];

const Plans = (props) => {
    const [select, setSelect] = useState(true);
    const [planIndex, setPlanIndex] = useState(0);
    const [planDetails, setPlanDetails] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(6644);
    const [onBoarding, setOnBoarding] = useState(false);
    const [reviewStoreData, setReviewStoreData] = useState("");
    const [selectedProductName, setSelectedProductName] = useState("");
    const [planName, setPlanName] = useState("");
    const [basketReceivedId, setBasketReceivedId] = useState("");
    const [planFlag, setPlanFlag] = useState(false);
    const [selectedPlanFlag, setSelectedPlanFlag] = useState(6644);
    const [modalMe, setModalMe] = useState(false)


    console.log(selectedPlan, selectedPlanFlag)
    const toSentenceCase = (str) => {
        if (!str) return '';
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    const [price, setPrice] = useState("");
    let location = useLocation();
    const history = useHistory();

    let plans = [
        {
            money: "CA$0.00",
            planName: "Bronze",
            bannerLimit: 2,
            deals: "25 Deals",
            store: "1 Store",
        },
        {
            money: "CA$29.00",
            planName: "Silver",
            bannerLimit: 5,
            deals: "100 Deals",
            store: "3 Store",
        },
        {
            money: "CA$49.00",
            planName: "Gold",
            bannerLimit: 10,
            deals: "Unlimited",
            store: "Unlimited",
        },
    ];

    const getPlanIndex = (ind, item) => {
        if (!onBoarding) {
            setSelectedPlan(item?.id);
            setSelect(true);
            setPlanIndex(ind);
            setPlanName(item?.title);
            setPrice(item?.price);
        } else {
            toast.error("You can't change the plan")
        }
    };

    const onBoardStatus = async () => {
        await getMethod(urls.verifyOnboardStatus).then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                setOnBoarding(response?.data?.status);
                if (response?.data?.status) {
                    getReviewData();
                }
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    const goToReview = () => {
        history.push("/review");
    };

    useEffect(() => {
        onBoardStatus();
    }, []);

    const getPlansDetails = async (orderDetails) => {
        setLoading(true);
        let storeId = getStoreId();

        await getMethod(
            urls.plans + storeId + "/product/subscriptionproduct/?slug_value=subscription"
        ).then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                if (response?.data?.planFlag && response?.data?.planFlag?.length > 0) {
                    setSelectedPlanFlag(response?.data?.planFlag?.[0]?.productId);
                    setPlanFlag(true);
                    setSelectedPlan(response?.data?.planFlag?.[0]?.productId);
                    let indexToBeSelected = response?.data?.response.findIndex((obj) => obj?.id === response?.data?.planFlag?.[0]?.productId);
                    setPlanIndex(indexToBeSelected);
                }
                if (response?.data?.response && response?.data?.response?.length > 0) {
                    setPlanDetails(response?.data?.response);
                    if (orderDetails !== null && orderDetails && orderDetails?.length > 0) {
                        let indexToBeSelected = response?.data?.response.findIndex((obj) => obj?.title === orderDetails?.[0]?.product_name);
                        setSelectedPlan(response?.data?.response?.[indexToBeSelected]?.id);
                        getPlanIndex(indexToBeSelected, response?.data?.response?.[indexToBeSelected]);
                    } else {
                        if (response?.data?.response?.[planIndex] !== undefined) {
                            setSelectedPlan(response?.data?.response?.[planIndex]?.id);
                            getPlanIndex(planIndex, response?.data?.response?.[planIndex]?.id);
                        }
                    }
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }
        });
    };

    const getPaymentMethodInfo = async () => {
        setLoading(true);
        await getMethod(urls.getCustomerStripeId).then((response) => {
            if (response.status === 200 || response?.status === 201) {
                getPlansDetails(response?.data?.order_detail);
                if (response?.data?.order_detail !== null && response?.data?.order_detail && response?.data?.order_detail?.length > 0) {
                    setSelectedProductName(response?.data?.order_detail?.[0]?.product_name);
                    setPlanName(response?.data?.order_detail?.[0]?.product_name);
                    setPrice(response?.data?.order_detail?.[0]?.price);
                }
            }
        });
    };

    useEffect(() => {
        getCurrentStatus();
        getPaymentMethodInfo();
    }, []);

    window.$progressStatus = "80%";

    const getCurrentStatus = async () => {
        await getMethod(urls.getUserDetailsMe).then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                setModalMe(response?.data?.on_hold_check_flag)
                window.$step = response?.data?.signup_step;
                if (response?.data?.signup_step <= 3) {
                    localStorage.setItem("progressStatus", response?.data?.signup_step);
                }
            }
        }).catch((error) => {
            console.log("error", error);
        });
    };

    const addToCart = async () => {

        if (planIndex !== "" && planIndex !== undefined) {
            if (planFlag) {
                let body = {
                    url: baseUrl + urls.postPlan + selectedPlanFlag + "/",
                    quantity: -1,
                };
                await postMethodWithPlatform(urls.addInBasket, body, "merchant").then((response) => {
                    if (response.status === 200 || response?.status === 201) {
                        addTocarts();
                    }
                }).catch((error) => {
                    console.log("error", error);
                });
                if (!onBoarding) {
                    goToReview();
                }
            } else {
                addTocarts();
            }
        }
        // else {
        //     toast.error("Please select one of the plan");
        // }

    };

    const addTocarts = async () => {
        let body = {
            url: baseUrl + urls.postPlan + selectedPlanFlag + "/",
            quantity: 1,
        };

        await postMethodWithPlatform(urls.addInBasket, body, "merchant").then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                console.log(response);
            }
        }).catch((error) => {
            console.log("error", error);
        });
        if (!onBoarding) {
            goToReview();
        }
    };

    const getReviewData = async () => {
        let storeid = getStoreId();
        let response_data = {};
        await getMethod(urls.vendorOnboarding + storeid).then((response) => {
            if (response.status === 200 || response?.status === 201) {
                setReviewStoreData(response?.data);
            }
        }).catch((error) => {
            console.log("error", error);
        });
    };

    const getPhoneFormat = (phone) => {
        const str = phone;
        const format = str.replace(/[^a-z\d\s]+/gi, "");
        const removeSpace = format.split(" ").join("");
        return "+" + removeSpace;
    };

    const getBaskets = async () => {
        await getMethod(urls.getBasket).then(async (response) => {
            if (response.status === 200 || response?.status === 201) {
                setBasketReceivedId(response?.data?.[0]?.id);

                if (reviewStoreData?.financial_info?.billing_address !== undefined) {
                    let body = {
                        basket: baseUrl + urls.getBasket + response?.data?.[0]?.id + "/",
                        guest_email: reviewStoreData?.user?.email,
                        total: price,
                        shipping_charge: {
                            currency: "USD",
                            excl_tax: "0.0",
                            tax: "0.0",
                        },
                        shipping_method_code: "no-shipping-required",
                        shipping_address: {
                            country: baseUrl + urls?.country + reviewStoreData?.business_address?.country + "/",
                            first_name: reviewStoreData?.financial_info?.billing_address?.first_name !== undefined ? reviewStoreData?.financial_info?.billing_address?.first_name : "",
                            last_name: reviewStoreData?.financial_info?.billing_address?.last_name !== undefined ? reviewStoreData?.financial_info?.billing_address?.last_name : "",
                            line1: reviewStoreData?.financial_info?.billing_address?.line1 !== undefined ? reviewStoreData?.financial_info?.billing_address?.line1 : "",
                            line2: reviewStoreData?.financial_info?.billing_address?.unit !== undefined ? reviewStoreData?.financial_info?.billing_address?.unit.toString() : "",
                            line3: reviewStoreData?.financial_info?.billing_address?.province !== undefined && reviewStoreData?.financial_info?.billing_address?.province !== "" ? reviewStoreData?.financial_info?.billing_address?.province : "",
                            line4: reviewStoreData?.financial_info?.billing_address?.city !== undefined ? reviewStoreData?.financial_info?.billing_address?.city : "",
                            notes: "",
                            phone_number: reviewStoreData?.financial_info?.billing_address?.phone_number !== undefined ? getPhoneFormat(reviewStoreData?.financial_info?.billing_address?.phone_number) : "",
                            postcode: reviewStoreData?.financial_info?.billing_address?.postcode !== undefined ? reviewStoreData?.financial_info?.billing_address?.postcode : "",
                            state: reviewStoreData?.financial_info?.billing_address?.province !== undefined && reviewStoreData?.financial_info?.billing_address?.province !== "" ? reviewStoreData?.financial_info?.billing_address?.province : reviewStoreData?.financial_info?.billing_address?.state !== undefined && reviewStoreData?.financial_info?.billing_address?.state !== "" ? reviewStoreData?.financial_info?.billing_address?.state : "",
                            title: "",
                        },
                        billing_address: {
                            country: baseUrl + urls?.country + reviewStoreData?.business_address?.country + "/",
                            first_name: reviewStoreData?.financial_info?.billing_address?.first_name !== undefined ? reviewStoreData?.financial_info?.billing_address?.first_name : "",
                            last_name: reviewStoreData?.financial_info?.billing_address?.last_name !== undefined ? reviewStoreData?.financial_info?.billing_address?.last_name : "",
                            line1: reviewStoreData?.financial_info?.billing_address?.line1 !== undefined ? reviewStoreData?.financial_info?.billing_address?.line1 : "",
                            line2: reviewStoreData?.financial_info?.billing_address?.unit !== undefined ? reviewStoreData?.financial_info?.billing_address?.unit.toString() : "",
                            line3: reviewStoreData?.financial_info?.billing_address?.province !== undefined && reviewStoreData?.financial_info?.billing_address?.province !== "" ? reviewStoreData?.financial_info?.billing_address?.province : "",
                            line4: reviewStoreData?.financial_info?.billing_address?.city !== undefined ? reviewStoreData?.financial_info?.billing_address?.city : "",
                            notes: "",
                            phone_number: reviewStoreData?.financial_info?.billing_address?.phone_number !== undefined ? getPhoneFormat(reviewStoreData?.financial_info?.billing_address?.phone_number) : "",
                            postcode: reviewStoreData?.financial_info?.billing_address?.postcode !== undefined ? reviewStoreData?.financial_info?.billing_address?.postcode : "",
                            state: reviewStoreData?.financial_info?.billing_address?.province !== undefined && reviewStoreData?.financial_info?.billing_address?.province !== "" ? reviewStoreData?.financial_info?.billing_address?.province : reviewStoreData?.financial_info?.billing_address?.state !== undefined && reviewStoreData?.financial_info?.billing_address?.state !== "" ? reviewStoreData?.financial_info?.billing_address?.state : "",
                            title: "",
                        },
                    };

                    await postMethodWithPlatform(urls.checkout, body, "merchant").then((response) => {
                        if (response.status === 200 || response?.status === 201) {
                            history.push("/settings/shippingDelivery");
                        } else {
                            toast.error("Something went wrong");
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                }
            }
        });
    };

    const checkoutToChangePlan = async () => {
        if (planName !== selectedProductName) {
            await addToCart();
            await getBaskets();
        } else {
            history.push("/settings/shippingDelivery");

        }
    };

    // const stripHtmlTags = (html) => {
    //     return html
    //         .replace(/<\/?[^>]+(>|$)/g, "") // Remove HTML tags
    //         .split(/[\n:]+/); // Split by both newline and colon
    // };
    // const ProductDescription = ({ description }) => {
    //     const lines = stripHtmlTags(description);

    //     const formatFirstLine = (line) => {
    //         if (line.length === 0) return line;
    //         return line.charAt(0).toUpperCase() + line.slice(1).toLowerCase();
    //     };
    //     return (
    //         <div>
    //             {lines.map((line, index) => (
    //                 <p
    //                     key={index}
    //                     style={index === 0 ? { fontSize: '24px', fontWeight: 'bold' } : { fontSize: '16px' }}
    //                 >
    //                     {index === 0 ? formatFirstLine(line) : line}
    //                 </p>
    //             ))}
    //         </div>
    //     );
    // };

      const stripHtmlTags = (html) => {
        return html
            .replace(/<\/?[^>]+(>|$)/g, "") // Remove HTML tags
            .split(/[\n:]+/); // Split by both newline and colon
    };
    
    const ProductDescription = ({ description }) => {
        const lines = stripHtmlTags(description);
    
        const formatFirstLine = (line) => {
            if (line.length === 0) return line;
            return line.charAt(0).toUpperCase() + line.slice(1).toLowerCase();
        };
    
        const highlightKeywords = (text) => {
            const keywords = [
                'Stores/Accounts',
                'Products Inventory',
                'Dedicated Store Page',
                'Processing Fee'
            ];
            
            keywords.forEach(keyword => {
                const regex = new RegExp(`(${keyword})`, 'gi');
                text = text.replace(regex, '<span style="font-size:18px; font-weight:bold;">$1</span>');
            });
    
            return text;
        };
    
        return (
            <div>
                {lines.map((line, index) => (
                    <p
                        key={index}
                        style={index === 0 ? { fontSize: '24px', fontWeight: 'bold' } : { fontSize: '16px' }}
                        dangerouslySetInnerHTML={{ __html: index === 0 ? formatFirstLine(highlightKeywords(line)) : highlightKeywords(line) }}
                    />
                ))}
            </div>
        );
    };
    

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <h1 className="h5-label mt-3">Shopez Plans</h1>
                    <div className="muiDescripationInfo">
                        Shopez presents a variety of plans designed to cater your specific needs, empowering you with the flexibility essential for scaling your business seamlessly.
                    </div>
                </div>
                <OnHoldModal isOnHold={modalMe} />
            </div>

            <div className="row mainView">
                {!loading ?
                    planDetails && planDetails?.length > 0 && planDetails.map((item, index) => {
                        return (
                            <div className="col-lg-4 col-md-6">
                                <div className="card cardDesign" data-ribbon={item?.title}>
                                    {/* {item?.title !== "Bronze" &&
                                        <div className="p-3 available-color">Available Soon</div>

                                    } */}
                                    <div className="card-header cardHeading text-center">
                                        <div className="plan_heading">
                                            ${planDetails?.[index]?.price}
                                        </div>
                                        <div class="cardText">Per Month</div>
                                    </div>

                                    {/* {planIndex === index ? (
                                        <div style={{ display: "flex", justifyContent: "center" }}>

                                            <div class="btn btn-primary pt-3 pb-3 mt-4 mb-4" onClick={() => getPlanIndex(index, item)} style={{ width: "80%" }}>
                                                Selected
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <div class="btn btn-cancle pt-3 pb-3 mt-4 mb-4" onClick={() => getPlanIndex(index, item)} style={{ width: "80%" }}>
                                                Choose this plan
                                            </div>
                                        </div>
                                    )} */}
                                    {item?.title === "Bronze" ? (
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <div class="btn btn-primary pt-3 pb-3 mt-4 mb-4" onClick={() => getPlanIndex(index, item)} style={{ width: "80%" }}>
                                                Selected
                                            </div>
                                        </div>
                                    ) : (
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <div class="btn btn-available pt-3 pb-3 mt-4 mb-4" onClick={() => getPlanIndex(index, item)} style={{ width: "80%", pointerEvents: "none", opacity: 1 }}>
                                                Available Soon
                                            </div>
                                        </div>
                                    )}

                                    <div class="card-body cardBody text-center">
                                        {/* {stripHtmlTags(item?.description)} */}
                                        <ProductDescription description={item?.description} />

                                        {planDetails?.[index]?.attributes !== null && planDetails?.[index]?.attributes && planDetails?.[index]?.attributes.length > 0 ? (
                                            <>
                                                <p class="cardText pb-2">
                                                    This plan includes the following benefits:
                                                </p>
                                                <p className="smallHeadings">Banner limit</p>
                                                <p className="cardText">
                                                    {planDetails?.[index]?.attributes?.[0]?.value} Banner
                                                </p>
                                                <p className="smallHeadings">Products limit</p>
                                                <p className="cardText">
                                                    {planDetails?.[index]?.attributes?.[3]?.value ? "Unlimited" : "Limited"}
                                                </p>
                                                <p className="smallHeadings">Deals/Promotions Limit</p>
                                                <p className="cardText">
                                                    {planDetails?.[index]?.attributes?.[1]?.value}
                                                </p>
                                                <p className="smallHeadings">Stores/Accounts</p>
                                                <p className="cardText">
                                                    {planDetails?.[index]?.attributes?.[2]?.value}
                                                </p>
                                            </>
                                        ) : (
                                            ""
                                        )}

                                        {/* {planIndex === index ? (
                                            <div class="btn btn-primary w-100 pt-3 pb-3 mt-4 mb-4" onClick={() => getPlanIndex(index, item)}>
                                                Selected
                                            </div>
                                        ) : (
                                            <div class="btn btn-cancle w-100 pt-3 pb-3 mt-4 mb-4" onClick={() => getPlanIndex(index, item)}>
                                                Choose this plan
                                            </div>
                                        )} */}
                                    </div>
                                </div>
                            </div>
                        );
                    })

                    :
                    skeletonArray.map((k, i) => (
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="card cardDesign">
                                <Skeleton variant="rect" height={400} />
                            </div>
                        </div>
                    ))

                }

                {planDetails && planDetails?.length > 0 ? (
                    <div className="col-12  mt-2 d-flex justify-content-end">
                        {location.pathname === "/settings/financials" ? (
                            <div ref={props.reference} onClick={() => checkoutToChangePlan()}>
                            </div>
                        ) : (
                            <div ref={props.reference} onClick={() => addToCart()}>
                            </div>

                        )}
                    </div>
                ) : null}

            </div>


        </>
    );
};
export default Plans;
