import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import ListSubheader from "@material-ui/core/ListSubheader";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import InputMask from "react-input-mask";
import InputLabel from "@material-ui/core/InputLabel";
import { OutlinedInput } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Geosuggest from "react-geosuggest";
import { toast } from "react-toastify";
import Button from "../../../../components/button/button";
import { getMethod, postMethodWithTokenProgress, postMethodWithToken, putMethodWithToken } from "../../../../utils/services/api";
import urls from "../../../../utils/services/apiUrl.json";
import SearchIcon from "@material-ui/icons/Search";
import OnHoldModal from "../../../../components/OnHoldModal/onHoldModal";

const checkSupplier = supplier => {

    const emptyKeys = [
        'first_name',
        'last_name',
        'email',
        'phone_number',
        'address',
        'country',
        'state',
        'city',
        'postal_code'
    ];

    const emptyKeysErrors = [
        'first name',
        'last name',
        'email',
        'phone number',
        'address',
        'country',
        'state',
        'city',
        'zip/postal code'
    ];

    for (let i = 0; i < emptyKeys.length; i++) {
        if (!supplier[emptyKeys[i]]) {
            return `Please fill in ${emptyKeysErrors[i]}`;
        }
    }

    return '';
};


function Supplier(props) {
    const geosuggestEl = useRef("");
    const google = window.google;

    const history = useHistory();
    const params = useParams();

    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);

    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [stateDataList, setStateDataList] = useState([]);
    const [cityDataList, setCityDataList] = useState([]);

    const [countrySelected, setCountrySelected] = useState("");
    const [stateSelected, setStateSelected] = useState("");
    const [citySelected, setCitySelected] = useState("");

    const [stateName, setStateName] = useState("");
    const [cityName, setCityName] = useState("");
    const [modalMe, setModalMe] = useState(false)

    const [formValue, setFormValue] = useState({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        address: "",
        country: "",
        state: "",
        city: "",
        postal_code: "",
        website: ""
    });

    const toSentenceCase = (str) => {
        if (!str) return '';
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    useEffect(() => {
        getCountryList();
        getSteps()

        if (params.id !== 'create') {
            getSupplierDetails();
        }
    }, []);

    const getSteps = async () => {
        await getMethod(urls.getCustomerStripeId).then((response) => {
            if (response.status === 200) {
                setModalMe(response.data.on_hold_check_flag)
            }
        });
    }

    const getSupplierDetails = async () => {
        await getMethod(urls.suppliers + params.id + "/").then((response) => {
            if (response.status === 200 | response.status === 201) {


                let countryValue = response.data.country;
                let stateValue = response.data.state;
                let cityValue = response.data.city;
                // let country_id = '';
                let country_id = response.data.country;
                let state_id = '';

                if (countryValue) {
                    // countryList.map((item) => {
                    //     if (item.printable_name === countryValue) {
                    //         country_id = item.iso_3166_1_a3;
                    //     }
                    // });

                    if (country_id) {
                        getMethod(urls.getStates + '?country_name=' + country_id).then((response) => {
                            if (response.status === 200) {
                                let results = response.data.response[0].data.states

                                setStateList(results);
                                setStateDataList(results);

                                if (results.length > 0 && stateValue) {
                                    let indexStateID = '';

                                    results.map((item) => {
                                        if (item.stateName === stateValue) {
                                            indexStateID = item.id;
                                        }
                                    });

                                    if (indexStateID) {
                                        state_id = indexStateID;
                                        setStateSelected(indexStateID);

                                        setFormValue((prevState) => ({
                                            ...prevState,
                                            state: indexStateID,
                                        }));

                                        if (country_id && state_id) {
                                            getCityListWithName(country_id, state_id, cityValue)
                                        }
                                    }
                                }
                            }
                        });
                    }
                }

                setFormValue((prev) => ({
                    ...prev,
                    first_name: response.data ? response.data.first_name : "",
                    last_name: response.data ? response.data.last_name : "",
                    email: response.data ? response.data.email : "",
                    phone_number: response.data ? response.data.phone_number : "",
                    address: response.data ? response.data.address : "",
                    country: response.data ? response.data.country : "",
                    postal_code: response.data ? response.data.postal_code : "",
                    website: response.data ? response.data.website : ""
                })
                )
            }
        }).catch((error) => {
            console.log("error", error);
        });
    }

    const getCountryList = async () => {
        await getMethod(urls.getCountry).then((response) => {
            if (response.status === 200) {
                setCountryList(response.data);
            }
        });
    };

    const getStateList = async (value) => {
        await getMethod(urls.getStates + '?country_name=' + value).then((response) => {
            if (response.status === 200) {
                setStateList(response.data.response[0].data.states);
                setStateDataList(response.data.response[0].data.states);
            }
        });
    };

    const getCityList = async (value) => {
        await getMethod(urls.getStates + '?country_name=' + formValue.country + '&state_id=' + value).then((response) => {
            if (response.status === 200) {
                setCityList(response.data.response[0].data.cities);
                setCityDataList(response.data.response[0].data.cities);
            }
        });
    };

    const getCityListWithName = async (countryId, stateId, name) => {
        await getMethod(urls.getStates + '?country_name=' + countryId + '&state_id=' + stateId).then((response) => {
            if (response.status === 200) {
                let results = response.data.response[0].data.cities;
                setCityList(results);
                setCityDataList(results);

                if (name && results.length > 0) {
                    let indexCityID = '';

                    results.map((item) => {
                        if (item.cityName === name) {
                            indexCityID = item.id;
                        }
                    });

                    if (indexCityID) {
                        setCitySelected(indexCityID)

                        setFormValue((prevState) => ({
                            ...prevState,
                            city: indexCityID,
                        }));
                    }
                }
            }
        });
    }

    const handleCountryChange = (event) => {
        setCountrySelected(event.target.value);
        setFormValue({ ...formValue, [event.target.name]: event.target.value });

        setFormValue((prevState) => ({
            ...prevState,
            state: '',
            city: '',
            postal_code: '',
        }));
        setStateSelected('')
        setCitySelected('');

        getStateList(event.target.value);
    };

    const handleStateChange = (event) => {
        if (event.target.value !== undefined) {
            setStateSelected(event.target.value);
            setFormValue({ ...formValue, [event.target.name]: event.target.value });

            setFormValue((prevState) => ({
                ...prevState,
                city: '',
            }));
            setCitySelected('');

            getCityList(event.target.value);
        }
    };

    const handleCityChange = (event) => {
        if (event.target.value !== undefined) {
            setCitySelected(event.target.value);
            setFormValue({ ...formValue, [event.target.name]: event.target.value });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValue((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const onSuggestSelect = (suggest) => {

        if (suggest) {
            setCountrySelected('');
            setStateSelected('');
            setCitySelected('');

            setFormValue((prevState) => ({
                ...prevState,
                postal_code: "",
                country: "",
                state: "",
                city: ""
            }));

            const components = suggest.gmaps.address_components;

            let state_id = '';
            let streetNumberValue = '';
            let routeValue = '';
            let cityValue = '';
            let postCodeValue = '';
            let stateValue = '';
            let stateAddressValue = '';
            let countryValue = '';
            let addressValue = '';

            for (let key of components) {
                if (key.types[0] === "locality" || key.types[1] === "sublocality") {
                    setCityName(key.long_name)
                    cityValue = key.long_name;
                }

                if (key.types[0] === "administrative_area_level_1") {
                    stateValue = key.long_name;
                    stateAddressValue = key.short_name;
                    setStateName(key.long_name)
                }

                if (key.types[0] === "country") {
                    if (key.short_name === 'US') {
                        setCountrySelected('USA')
                        countryValue = 'USA';

                        setFormValue((prevState) => ({
                            ...prevState,
                            country: 'USA',
                        }));

                    } else if (key.short_name === 'CA') {
                        setCountrySelected('CAN')
                        countryValue = 'CAN';

                        setFormValue((prevState) => ({
                            ...prevState,
                            country: 'CAN',
                        }));
                    } else {
                        setCountrySelected(key.short_name)
                        countryValue = key.short_name;

                        setFormValue((prevState) => ({
                            ...prevState,
                            country: key.short_name,
                        }));
                    }
                }

                if (key.types[0] === "postal_code") {
                    postCodeValue = key.long_name;
                    setFormValue((prevState) => ({
                        ...prevState,
                        postal_code: key.long_name,
                    }));
                }
                if (key.types[0] === "route") {
                    routeValue = key.short_name;
                }

                if (key.types[0] === "street_number") {
                    streetNumberValue = key.long_name;
                }
            }

            if (stateValue && countryValue) {
                getMethod(urls.getStates + '?country_name=' + countryValue).then((response) => {
                    if (response.status === 200) {
                        let results = response.data.response[0].data.states

                        setStateList(results);
                        setStateDataList(results);

                        if (results.length > 0) {
                            let indexStateID = '';

                            results.map((item) => {
                                if (item.stateName === stateValue) {
                                    indexStateID = item.id;
                                }
                            });

                            if (indexStateID) {
                                state_id = indexStateID;
                                setStateSelected(indexStateID);

                                setFormValue((prevState) => ({
                                    ...prevState,
                                    state: indexStateID,
                                }));

                                if (countryValue && state_id) {
                                    getCityListWithName(countryValue, state_id, cityValue)
                                }
                            }
                        }
                    }
                });
            }

            if (streetNumberValue !== "") {
                addressValue += streetNumberValue;
            }

            if (routeValue !== "" && streetNumberValue !== "") {
                addressValue += ", " + routeValue;
            } else if (routeValue !== "") {
                addressValue += routeValue;
            }

            setFormValue((prevState) => ({
                ...prevState,
                ["address"]: addressValue
            }));

            geosuggestEl.current.update(addressValue);
        }
    };

    const stateFilter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = stateDataList.filter((item) => {
                return item.stateName.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setStateList(results);
        } else {
            setStateList(stateDataList);
        }
    };

    const cityFilter = (e) => {
        const keyword = e.target.value;
        if (keyword !== '') {
            const results = cityDataList.filter((item) => {
                return item.cityName.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setCityList(results);
        } else {
            setCityList(cityDataList);
        }
    };

    const submitData = async () => {
        const error = checkSupplier(formValue);

        if (error) {
            toast.error(error);
        } else {
            if (params.id === "create") {
                await postMethodWithToken(urls.suppliers, formValue).then((response) => {
                    if (response.status === 201 || response.status === 200) {
                        toast.success(toSentenceCase(response.data.response));
                        history.push('/contacts/suppliers')
                    } else {
                        toast.error(toSentenceCase(response.data.error));
                    }
                });
            } else {
                await putMethodWithToken(urls.suppliers + params.id + "/", formValue).then((response) => {
                    if (response.status === 201 || response.status === 200) {
                        toast.success(toSentenceCase(response.data.response));
                        history.push('/contacts/suppliers')
                    } else {
                        toast.error(toSentenceCase(response.data.error));
                    }
                });
            }
        }
    }

    return (
        <div className={props.menu ? "screenLeftMargin w-100" :"screenLeftMarginResponse w-100"}>
            <div className="row ml-3 mt-3 mr-3">
                <div onClick={() => history.push("/contacts/suppliers")} style={{ cursor: "pointer" }}>
                    <ArrowBackIcon /> <a style={{ color: "#4472C4" }} className="ml-2">Suppliers</a>
                </div>
            </div>

            <div className="row ml-3 mt-2 mr-3">
                <div className="col-12">
                    <h1 className="heading-title">{"Create Supplier"}</h1>
                </div>
            </div>

            <div className="row ml-3 mr-3">
                <div className="col-12">
                    <ValidatorForm>
                        <div className="row mt-3">
                            <div className="col-6">
                                <TextValidator
                                    required
                                    errorMessages={[
                                        "Please Enter First Name"
                                    ]}
                                    validators={[
                                        "required",
                                    ]}
                                    className="w-100"
                                    inputProps={{ maxLength: 20 }}
                                    value={formValue?.first_name}
                                    onChange={handleInputChange}
                                    name="first_name"
                                    label="First Name"
                                    variant="outlined"
                                />
                            </div>
                            <div className="col-6">
                                <TextValidator
                                    required
                                    errorMessages={[
                                        "Please Enter Last Name"
                                    ]}
                                    validators={[
                                        "required",
                                    ]}
                                    className="w-100"
                                    inputProps={{ maxLength: 20 }}
                                    value={formValue?.last_name}
                                    onChange={handleInputChange}
                                    name="last_name"
                                    label="Last Name"
                                    variant="outlined"
                                />
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-6">
                                <TextValidator
                                    required
                                    errorMessages={[
                                        "Please Enter Email"
                                    ]}
                                    validators={[
                                        "required",
                                    ]}
                                    className="w-100"
                                    value={formValue?.email}
                                    onChange={handleInputChange}
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                />
                            </div>
                            <div className="col-6">
                                <InputMask
                                    mask={"+1 (999) 999-9999"}
                                    maskChar="_"
                                    value={formValue?.phone_number}
                                    onChange={handleInputChange}
                                    disabled={false}
                                >
                                    {() => (
                                        <TextValidator
                                            required
                                            errorMessages={[
                                                "Please Enter Valid Phone Number"
                                            ]}
                                            validators={[
                                                "required",
                                            ]}
                                            className="w-100"
                                            name="phone_number"
                                            label="Phone Number"
                                            variant="outlined"
                                        />
                                    )}
                                </InputMask>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-6">
                                <Geosuggest
                                    id="aboutstore"
                                    ref={geosuggestEl}
                                    required
                                    className="Aboutstore_textfield"
                                    placeholder="Address"
                                    name="address"
                                    fields={["address_components", "geometry"]}
                                    types={["address"]}
                                    country={["US", "CA"]}
                                    initialValue={formValue?.address}
                                    onSuggestSelect={onSuggestSelect}
                                    location={new google.maps.LatLng(lat, lng)}
                                    radius="100"
                                />
                            </div>
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-6">
                                        <FormControl variant="outlined">
                                            <InputLabel
                                                required
                                                id="country-select"
                                            >
                                                Country
                                            </InputLabel>
                                            <Select
                                                labelId="country-select"
                                                id="country-dropdown"
                                                value={formValue?.country}
                                                onChange={handleCountryChange}
                                                label="Country"
                                                name="country"
                                                errorMessages={["Please Select Country"]}
                                                validators={[
                                                    "required",
                                                ]}
                                            >
                                                {countryList.map((item) => {
                                                    return (
                                                        <MenuItem value={item.iso_3166_1_a3}> {item?.printable_name} </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-6">
                                        <FormControl variant="outlined">
                                            <InputLabel
                                                required
                                                id="country-select"
                                            >
                                                {formValue.country === 'CAN' ? 'Province' : 'State'}
                                            </InputLabel>
                                            <Select
                                                MenuProps={{ autoFocus: false }}
                                                labelId="country-select"
                                                id="country-dropdown"
                                                value={formValue?.state}
                                                defaultValue={formValue?.state}
                                                label={formValue.country === 'CAN' ? 'Province' : 'State'}
                                                name="state"
                                                errorMessages={["Please Select State"]}
                                                validators={[
                                                    "required",
                                                ]}
                                                onChange={(e) => handleStateChange(e)}
                                                onClose={() => setStateList(stateDataList)}
                                            >
                                                <ListSubheader>
                                                    <TextField
                                                        size="small"
                                                        autoFocus
                                                        placeholder="Type to search..."
                                                        fullWidth
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIcon />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        onChange={stateFilter}
                                                        onKeyDown={(e) => {
                                                            if (e.key !== "Escape") {
                                                                e.stopPropagation();
                                                            }
                                                        }}
                                                    />
                                                </ListSubheader>
                                                {stateList.length > 0 && stateList.map((item) => {
                                                    return (
                                                        <MenuItem value={item.id}> {item?.stateName} </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-6">
                                        <FormControl variant="outlined">
                                            <InputLabel
                                                required
                                                id="country-select"
                                            >
                                                City
                                            </InputLabel>
                                            <Select
                                                MenuProps={{ autoFocus: false }}
                                                labelId="country-select"
                                                id="country-dropdown"
                                                value={formValue?.city}
                                                defaultValue={formValue?.city}
                                                label="City"
                                                name="city"
                                                errorMessages={["Please Select City"]}
                                                validators={[
                                                    "required",
                                                ]}
                                                onChange={(e) => handleCityChange(e)}
                                                onClose={() => setCityList(cityDataList)}
                                            >
                                                <ListSubheader>
                                                    <TextField
                                                        size="small"
                                                        autoFocus
                                                        placeholder="Type to search..."
                                                        fullWidth
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position="start">
                                                                    <SearchIcon />
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                        onChange={cityFilter}
                                                        onKeyDown={(e) => {
                                                            if (e.key !== "Escape") {
                                                                e.stopPropagation();
                                                            }
                                                        }}
                                                    />
                                                </ListSubheader>
                                                {cityList.length > 0 && cityList.map((item) => {
                                                    return (
                                                        <MenuItem value={item.id}> {item?.cityName} </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="col-6">
                                        {formValue.country === "CAN" ?
                                            <InputMask
                                                mask={"a9a 9a9"}
                                                value={formValue.postal_code}
                                                disabled={false}
                                                onChange={handleInputChange}
                                            >
                                                {() => (
                                                    <TextValidator
                                                        required
                                                        errorMessages={[
                                                            "Please Enter Valid Postal Code"
                                                        ]}
                                                        validators={[
                                                            "required",
                                                        ]}
                                                        className="w-100"
                                                        name="postal_code"
                                                        label={'Postal Code'}
                                                        variant="outlined"
                                                    />
                                                )}
                                            </InputMask>
                                            :
                                            <InputMask
                                                mask={"99999"}
                                                value={formValue.postal_code}
                                                disabled={false}
                                                onChange={handleInputChange}
                                            >
                                                {() => (
                                                    <TextValidator
                                                        required
                                                        errorMessages={[
                                                            "Please Enter Valid ZIP Code"
                                                        ]}
                                                        validators={[
                                                            "required",
                                                        ]}
                                                        className="w-100"
                                                        name="postal_code"
                                                        label={'ZIP Code'}
                                                        variant="outlined"
                                                    />
                                                )}
                                            </InputMask>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                <TextValidator
                                    className="w-100"
                                    inputProps={{ maxLength: 30 }}
                                    value={formValue.website}
                                    onChange={handleInputChange}
                                    name="website"
                                    label="Website"
                                    variant="outlined"
                                />
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className="col-12 d-flex justify-content-end">
                                <Button type="onSubmit" onClick={() => history.push("/contacts/suppliers")} name="Cancel" className="btn btn-cancle mr-3" />
                                <Button type="onSubmit" onClick={() => submitData()} name="Save" className="btn btn-primary" />
                            </div>
                        </div>
                    </ValidatorForm>
                    <OnHoldModal isOnHold={modalMe} />
                </div>
            </div>

        </div>
    )
}
export default Supplier;