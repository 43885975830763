import React, { useState, useEffect } from "react";
import "./tabCollection.scss";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SwipeableViews from "react-swipeable-views";
import Button from "../../../../../../components/button/button";
import DetailSection from "../detailsTab/detailsTab";
import AddItemsSection from "../addItems/addItems";
import { getStoreId } from "../../../../../../utils/common";
import { getMethod, postMethodWithToken, putMethodWithToken } from "../../../../../../utils/services/api";
import urls from "../../../../../../utils/services/apiUrl.json";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import SearchDB from "../../../../../../assets/images/search.png";
import FilterDB from "../../../../../../assets/images/filter.png";
import ListingDB from "../../../../../../assets/images/listing.png";
import ListviewDB from "../../../../../../assets/images/list view.png";
import GridviewDB from "../../../../../../assets/images/grid view.png";
import DeleteDB from "../../../../../../assets/images/delete.png";
import AddCollection from "../../../../../../assets/images/addCollection.svg";
import SpinnerLoader from "../../../../../../components/loader/spinnerLoader";
import emptyStore from "../../../../../../assets/images/storelisting.png";
import NoImage from "../../../../../../assets/images/storelisting.png";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { toast } from "react-toastify";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from '@material-ui/icons/Search';
import { Col, Row } from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";

let timeOutId;

function TabCollection({ show, showListing, setShowListing }) {

    var panelOpen = window.$panelActive;
    const [currTab, setCurrTab] = useState(0);
    const [productListing, setProductListing] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [noMatch, setNoMatch] = useState(false);
    const [expandIndex, setExpandIndex] = useState("");
    const [collectionItems, setCollectionItems] = useState(showListing && showListing?.collectionProduct?.length > 0 ? showListing.collectionProduct.map((k, i) => k.id) : []);
    const [collectionListing, setCollectionListing] = useState(showListing ? showListing.collectionProduct : []);
    const [collectionName, setCollectionName] = useState(showListing ? showListing.collectionName :"");
    const [collectionDescription, setCollectionDescription] = useState(showListing ? showListing.collectionDescription :"");

    const [productIdList, setProductIdList] = useState(collectionItems ? collectionItems : []);
    const [productCategoryIdList, setProductCategoryIdList] = useState(collectionItems ? collectionItems : []);
    const [productList, setProductList] = useState(collectionListing ? collectionListing : []);
    const [productTotalList, setProductTotalList] = useState(collectionItems ? collectionItems : []);

    const [listingsDescription, setListingsDescription] = useState(
        "Collection can be used to manage your listings and Discounts.This information will not be seen by customers."
    );

    const toSentenceCase = (str) => {
        if (!str) return '';
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    const expandDropdown = (index) => {
        if (expandIndex === index) {
            setExpandIndex("");
        } else {
            setExpandIndex(index);
        }
    };

    function handleTabChange(event, newValue) {
        setCurrTab(newValue);
    }

    function handleChangeIndex(index) {
        setCurrTab(index);
    }

    const saveNnext = async (tabindex) => {
        window.$tabIndex = tabindex;
        if (tabindex === "details") {
            setCurrTab(1);
        } else if (tabindex === "additems") {
            setCurrTab(0);
        }
    };

    const goToCollectionListing = () => {
        setShowListing(false);
        window.$addCollection = false;
    };

    // useEffect(() => {
    //     if (!show) {
    //         getProductListing();
    //     }
    // }, []);

    const getProductListing = async () => {
        setLoading(true);
        let storeId = getStoreId();
        await getMethod(urls.listview + storeId).then((response) => {
            if (response.status === 200) {
                setProductListing(response?.data?.results);
                setLoading(false);
            }
        }).catch((error) => {
            console.log("error", error);
        });
        setLoading(false);
    };

    const debounce = (func, delay) => {
        return (...args) => {
            if (timeOutId) clearTimeout(timeOutId);

            timeOutId = setTimeout(() => {
                func.apply(null, args);
            }, delay);
        };
    };

    const handleSearchInputChange = (e) => {
        setSearchInput(e.target.value);
        debounceCall(e.target.value);
    };

    const searchProduct = async (text) => {
        let storeId = getStoreId();
        if (text === "") {
            setNoMatch(false);
            getProductListing();
        } else {
            await getMethod(urls.aboutStore + storeId + "/searchproduct/?product_name=" + text).then((response) => {
                if (response.status === 200) {
                    if (response?.data?.results?.data?.length > 0) {
                        setNoMatch(false);
                        setProductListing(response?.data?.results?.data);
                    } else {
                        setNoMatch(true);
                    }
                }
            });
        }
    };

    const showProductImage = (item) => {
        if (item.images.length > 0) {
            return (
                <img height="50" width="50" style={{ marginLeft: "15px" }} src={item.images[0].original} alt="" />
            );
        } else {
            return <img height="50" width="50" style={{ marginLeft: "15px" }} src={NoImage} alt="" />;
        }
    };


    const debounceCall = debounce(searchProduct, 500);

    const handleCloseList = () => {
        setSearchInput("")
        setProductListing([]);
    }

    const handleQuestionAdd = async (event, id, productName, upc, sku, saleprice, listprice, image) => {
        const list = [...productIdList];
        list.push(id);
        setProductIdList(list);

        const qlist = [...productList];
        qlist.push({ "id": id, "images": image.images, "product_name": productName, "upc": upc, "partner_sku": sku, "price": saleprice, "compare_at_price": listprice });
        setProductList(qlist);

        const plist = [...productTotalList];
        plist.push(id);
        setProductTotalList(plist);

    };

    const handleQuestionRemove = async (event, id) => {
        const list = [...productIdList];
        let index = list.indexOf(id);
        list.splice(index, 1);
        setProductIdList(list);

        const qlist = [...productList];
        let indexs = qlist.findIndex(obj => obj.id === id);
        if (indexs !== -1) {
            qlist.splice(indexs, 1);
            setProductList(qlist);
        }

        const plist = [...productTotalList];
        let pindex = plist.indexOf(id);
        plist.splice(pindex, 1);
        setProductTotalList(plist);
    };

    const handleQuestionCategoryAdd = async (event, id, index, productName, upc, sku, saleprice, listprice, image) => {

        const qlist = [...productList];
        qlist.push({ "id": id, "images": image.images, "product_name": productName, "upc": upc, "partner_sku": sku, "price": saleprice, "compare_at_price": listprice });
        const plist = [...productTotalList];
        /** Category wise productAdd start **/
        if (productListing?.[index]?.children?.length > 0) {
            let ioscategorylist = productListing?.[index]?.children;

            const list = [...productIdList];
            ioscategorylist.forEach((x, i) => {
                if (!productIdList.includes(x.id)) {
                    list.push(x.id);
                    setProductIdList(list);

                    qlist.push({ "id": x.id, "images": x.images, "product_name": x.product_name, "upc": x.upc, "partner_sku": x.partner_sku, "price": x.price, "compare_at_price": x.compare_at_price })
                }
                if (!productTotalList.includes(x.id)) {
                    plist.push(x.id);
                }
            });
        }
        /** Category wise productAdd end **/
        setProductList(qlist);
        const clist = [...productCategoryIdList];
        clist.push(id);
        setProductCategoryIdList(clist);

        plist.push(id);
        setProductTotalList(plist);

    };

    const handleQuestionCategoryRemove = async (event, id, index) => {
        /** Category wise productRemove start **/
        const qlist = [...productList];
        const plist = [...productTotalList];

        if (productListing?.[index]?.children?.length > 0) {
            let ioscategorylist = productListing?.[index]?.children;

            const list = [...productIdList];

            ioscategorylist.forEach((x, i) => {
                let index = list.indexOf(x.id);
                list.splice(index, 1);
                setProductIdList(list);

                let indx = qlist.findIndex(obj => obj.id === x.id);
                if (indx !== -1) {
                    qlist.splice(indx, 1);
                    setProductList(qlist);
                }

                let pindex = plist.indexOf(x.id);
                plist.splice(pindex, 1);
                setProductTotalList(plist);

            });
        }
        /** Category wise productRemove end **/

        const clist = [...productCategoryIdList];
        let indexs = clist.indexOf(id);
        clist.splice(indexs, 1);
        setProductCategoryIdList(clist);

        let indx = qlist.findIndex(obj => obj.id === id);
        if (indx !== -1) {
            qlist.splice(indx, 1);
            setProductList(qlist);
        }

        let pindex = plist.indexOf(id);
        plist.splice(pindex, 1);
        setProductTotalList(plist);

    }

    function addRemovebutton(value) {
        const flag = productIdList.includes(value);
        return flag;
    }

    function addRemoveCategorybutton(value) {
        const flag = productCategoryIdList.includes(value);
        return flag;
    }

    const handleProductRemove = (event, id, indx) => {
        const plist = [...productList];
        plist.splice(indx, 1);
        setProductList(plist);

        const list = [...productIdList];
        let index = list.indexOf(id);
        if (index !== -1) {
            list.splice(index, 1);
            setProductIdList(list);
        }

        const clist = [...productCategoryIdList];
        let indexs = clist.indexOf(id);
        if (indexs !== -1) {
            clist.splice(indexs, 1);
            setProductCategoryIdList(clist);
        }

        const pqlist = [...productTotalList];
        let pqindex = pqlist.indexOf(id);
        pqlist.splice(pqindex, 1);
        setProductTotalList(pqlist);

    }

    const AddItemsSection = () => {
        return (
            <div className="ml-4">
                <div className="row">
                    <div className=" addCollectionProduct col-xl-10 col-lg-10 col-md-10 mt-2">
                        <OutsideClickHandler onOutsideClick={() => handleCloseList()}>
                            <TextField
                                name="search"
                                label="Search Products"
                                variant="outlined"
                                value={searchInput}
                                onChange={(event) => handleSearchInputChange(event)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <div className="listItems">
                                {productListing.length > 0 && productListing.map((x, i) => {
                                    return (
                                        <>
                                            {i === 0 &&
                                                <Row className="mt-3 mb-3 headers" style={{ padding: "10px", borderBottom: "1px solid #C0C0C0" }}>
                                                    <Col md={10} sm={10} xs={10}>
                                                        <Row >
                                                            <Col md={2} sm={2} xs={2}>
                                                                <Row>
                                                                    <Col md={6} sm={6} xs={6}>
                                                                        S.No.
                                                                    </Col>

                                                                    <Col md={6} sm={6} xs={6}>

                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                            <Col md={2} sm={2} xs={2}>
                                                                Product Name
                                                            </Col>
                                                            <Col md={2} sm={2} xs={2}>
                                                                UPC
                                                            </Col>
                                                            <Col md={2} sm={2} xs={2}>
                                                                SKU
                                                            </Col>
                                                            <Col md={2} sm={2} xs={2}>
                                                                Sales Price
                                                            </Col>
                                                            <Col md={2} sm={2} xs={2}>
                                                                list Price
                                                            </Col>

                                                        </Row>
                                                    </Col>

                                                    <Col md={2} sm={2} xs={2}>
                                                        Action
                                                    </Col>

                                                </Row>
                                            }
                                            <Row className="productlist">
                                                <Col md={10} sm={10} xs={10}>
                                                    <Row >
                                                        <Col md={2} sm={2} xs={2}>
                                                            <Row>
                                                                <Col md={2} sm={2} xs={2}>
                                                                    <p key={x.id}>{i + 1}.</p>
                                                                </Col>

                                                                <Col md={6} sm={6} xs={6}>
                                                                    {showProductImage(x)}
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={2} sm={2} xs={2}>
                                                            <p key={x.id}>{x?.product_name}</p>
                                                        </Col>
                                                        <Col md={2} sm={2} xs={2}>
                                                            <p key={x.id}>{x?.upc}</p>
                                                        </Col>
                                                        <Col md={2} sm={2} xs={2}>
                                                            <p key={x.id}>{x?.partner_sku}</p>
                                                        </Col>
                                                        <Col md={2} sm={2} xs={2}>
                                                            <p key={x.id}>{x?.price}</p>
                                                        </Col>
                                                        <Col md={2} sm={2} xs={2}>
                                                            <p key={x.id}>{x?.compare_at_price}</p>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col md={2} sm={2} xs={2}>
                                                    {addRemoveCategorybutton(x.id) === true ?
                                                        <Button data-key={x.id} name="- Added" className="button" type="primary" onClick={(event) => handleQuestionCategoryRemove(event, x.id, i)} />
                                                        :
                                                        <Button data-key={x.id} name="+ Add" className="button" type="primary" onClick={(event) => handleQuestionCategoryAdd(event, x?.id, i, x?.product_name, x?.upc, x?.partner_sku, x?.price, x?.compare_at_price, x)} />
                                                    }
                                                </Col>
                                            </Row>

                                            {x.children.map((value, index) => {
                                                return (
                                                    <Row className="productlist" >
                                                        <Col md={10} sm={10} xs={10}>
                                                            <Row>
                                                                <Col md={2} sm={2} xs={2}>
                                                                    <Row>
                                                                        <Col md={2} sm={2} xs={2}>
                                                                        </Col>

                                                                        <Col md={6} sm={6} xs={6}>
                                                                            {showProductImage(value)}
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col md={2} sm={2} xs={2}>
                                                                    <p key={value.id}>{value?.product_name}</p>
                                                                </Col>
                                                                <Col md={2} sm={2} xs={2}>
                                                                    <p key={value.id}>{value?.upc}</p>
                                                                </Col>
                                                                <Col md={2} sm={2} xs={2}>
                                                                    <p key={value.id}>{value?.partner_sku}</p>
                                                                </Col>
                                                                <Col md={2} sm={2} xs={2}>
                                                                    <p key={value.id}>{value?.price}</p>
                                                                </Col>
                                                                <Col md={2} sm={2} xs={2}>
                                                                    <p key={value.id}>{value?.compare_at_price}</p>
                                                                </Col>
                                                            </Row>
                                                        </Col>

                                                        <Col md={2} sm={2} xs={2}>
                                                            {addRemovebutton(value.id) === true ?
                                                                <Button data-key={value.id} name="- Added" className="button" type="primary" onClick={(event) => handleQuestionRemove(event, value.id)} />
                                                                :
                                                                <Button data-key={value.id} name="+ Add" className="button" type="primary" onClick={(event) => handleQuestionAdd(event, value?.id, value?.product_name, value?.upc, value?.partner_sku, value?.price, value?.compare_at_price, value)} />
                                                            }
                                                        </Col>
                                                    </Row>
                                                );
                                            })}
                                        </>
                                    );
                                })
                                }
                            </div>
                        </OutsideClickHandler>

                        <div className="" style={{ width: "120%" }}>
                            <table className="listSectionAddItem">

                                {productList.map((items, indexes) => {
                                    return (
                                        <>
                                            {indexes === 0 &&
                                                <tr className="headers" style={{ textAlign: "center", alignItems: "center" }}>
                                                    <th style={{ width: "8%" }} >S.NO.</th>
                                                    <th style={{ width: "15%" }}>Product Name</th>
                                                    <th style={{ width: "15%" }}>UPC</th>
                                                    <th style={{ width: "15%" }}>SKU</th>
                                                    <th style={{ width: "15%" }}>Sales Price</th>
                                                    <th style={{ width: "15%" }}>list Price</th>
                                                    <th style={{ width: "15%" }}>Actions</th>
                                                </tr>
                                            }

                                            <tr key={indexes + 1} style={{ cursor: "pointer", backgroundColor: "#F2F2F2", height: "75px", textAlign: "center", alignItems: "center" }}>
                                                <td style={{ display: "flex", alignItems: "center", textAlign: "center", margin: "auto", height: "75px" }}>
                                                    <p style={{ marginRight: "25px", marginLeft: "25px", display: "flex", alignItems: "center", textAlign: "center", margin: "auto", height: "75px" }}>{indexes + 1}.</p>
                                                    {showProductImage(items)}
                                                </td>
                                                <td>{items?.product_name}</td>
                                                <td>{items?.upc}</td>
                                                <td>{items?.partner_sku}</td>
                                                <td>{items?.price}</td>
                                                <td>{items?.compare_at_price}</td>
                                                <td >
                                                    <Button data-key={items.id} name="Remove" className="button" onClick={(event) => handleProductRemove(event, items.id, indexes)} />
                                                </td>
                                            </tr>

                                        </>
                                    );
                                })}

                            </table>
                        </div>

                    </div>
                </div>
            </div>

        );
    };

    const getCollectionName = (val) => {
        setCollectionName(val);
    }

    const getCollectionDescription = (val) => {
        setCollectionDescription(val);
    }

    const CreateMerchantCollection = async () => {
        if (collectionName === "") {
            toast.error("Collection name is required")
        } else if (collectionDescription === "") {
            toast.error("Description name is required")
        } else if (productTotalList.length === 0) {
            toast.error("Atleast add one product");
        }else {

            let body = {
                "collectionName": collectionName,
                "collectionDescription": collectionDescription,
                "collectionProduct": productTotalList
            }

            if (show) {
                await putMethodWithToken(urls.updateMerchantCollection + showListing.id + "/", body).then((response) => {
                    if (response.status === 201 || response.status === 200) {
                        toast.success(toSentenceCase(response?.data?.response));
                        goToCollectionListing();
                    } else {
                        toast.error(toSentenceCase(response?.data?.response));
                    }
                });
            } else {
                await postMethodWithToken(urls.createMerchantCollection, body).then((response) => {
                    if (response.status === 201 || response.status === 200) {
                        toast.success(toSentenceCase(response?.data?.response));
                        goToCollectionListing();
                    } else {
                        toast.error(toSentenceCase(response?.data?.response));
                    }
                });
            }

        }

    }

    return (
        <>

            <div className="col-12 d-flex mt-3">
                <div onClick={() => goToCollectionListing()} style={{ cursor: "pointer" }}>
                    <ArrowBackIcon /> <a style={{ color: "#4472C4" }} className="ml-2">Collection</a>
                </div>
            </div>

            <div className="col-12 d-flex mt-2">
                <h1 className="heading-title">Create Collection</h1>
            </div>

            <div className="col-12 mt-2">
                <div className="muiDescripationInfo">{listingsDescription}</div>
            </div>

            <div className={panelOpen ? "width70" : "width70"}>
                <Tabs
                    value={currTab}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                // variant="fullWidth"
                >
                    <Tab label="Details" />
                    <Tab label="Add Items" />
                </Tabs>
            </div>

            <hr />
            <div className="width70">
                <SwipeableViews
                    index={currTab}
                    onChangeIndex={handleChangeIndex}
                    enableMouseEvents={false}
                >

                    <div className="ml-4" style={{ overflow: "hidden" }}>
                        <DetailSection showListing={showListing} getCollectionName={(val) => getCollectionName(val)} getCollectionDescription={(val) => getCollectionDescription(val)} />
                        <Button onClick={() => saveNnext("details")} name="Next" className="saveBButton" />
                        <Button onClick={() => goToCollectionListing()} name="Cancel" className="cancelBButton" />
                    </div>

                    <div style={{ height: "550px" }} className="mb-2">
                        {currTab === 1 &&
                            <div>
                                <div>
                                    {AddItemsSection()}
                                </div>
                                <Button onClick={() => CreateMerchantCollection()} name="Save" className="saveBButton" />
                                <Button onClick={() => saveNnext("additems")} name="Cancel" className="cancelBButton " />
                            </div>
                        }
                    </div>

                </SwipeableViews>
            </div >

            {/* </div> */}
        </>
    );
}
export default TabCollection;
