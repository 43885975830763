import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import { AreaChart, Area, LineChart, Line, ResponsiveContainer, CartesianGrid, Label, XAxis, Tooltip } from "recharts";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import "./home.scss";
import { getMethod } from "../../../utils/services/api";
import urls from "../../../utils/services/apiUrl.json";
import Modal from "react-modal";
import Button from "../../../components/button/button";
import { getStoreId } from "../../../utils/common";
import FilterDb from "../../../assets/images/Filter_F.svg";
import ExportIcon from '../../../assets/images/export-csv.png'
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import moment from "moment";
import { toast } from "react-toastify";
import format from 'date-fns/format';


function Home(props) {
    const data = [
        {
            name: 'Page A',
            uv: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: 'Page B',
            uv: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: 'Page C',
            uv: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: 'Page D',
            uv: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: 'Page E',
            uv: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'Page F',
            uv: 2390,
            pv: 3800,
            amt: 2500,
        },
        {
            name: 'Page G',
            uv: 3490,
            pv: 4300,
            amt: 2100,
        },
    ];
    const [allDashboardData, setAllDashboardData] = useState("");
    const [averageSalesofItems, setAverageSalesofItems] = useState("");
    const [averageSalesofItemsGraphData, setAverageSalesofItemsGraphData] = useState([]);
    const [returns, setReturns] = useState("");
    const [returnsGraphData, setReturnsGraphData] = useState([]);
    const [salesTax, setSalesTax] = useState("");
    const [salesGraphData, setSalesGraphData] = useState([]);
    const [transaction, setTransaction] = useState("");
    const [transactionGraphData, setTransactionGraphData] = useState([]);
    const [grossSales, setGrossSales] = useState("");
    const [grossSalesGraphData, setGrossSalesGraphData] = useState([]);
    const [netSales, setNetSales] = useState("");
    const [netSalesGraphData, setNetSalesGraphData] = useState([]);
    const [averageSalesValue, setAverageSalesValue] = useState("");
    const [averageSalesValueGraphData, setAverageSalesValueGraphData] = useState([]);
    const [grossprofit, setGrossprofit] = useState("");
    const [grossprofitGraphData, setGrossprofitGraphData] = useState([]);
    const [grossMargin, setGrossMargin] = useState("");
    const [grossMarginGraphData, setGrossMarginGraphData] = useState([]);
    const [grossMarkup, setGrossMarkup] = useState("");
    const [grossMarkupGraphData, setGrossMarkupGraphData] = useState([]);
    const [costOfGood, setCostOfGood] = useState("");
    const [costOfGoodGraphData, setCostOfGoodGraphData] = useState([]);
    const [filterDetail, setFilterDetail] = useState(false);
    const [filterSelectValue, setFilterSelectValue] = useState("custom")
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [exportStartDate, setExportStartDate] = useState("")
    const [exportEndDate, setExportEndDate] = useState("")
    const [exportShowStartDate, setExportShowStartDate] = useState("")
    const [exportShowEndDate, setExportShowEndDate] = useState("")
    const [showFilterDate, setShowFilterDate] = useState(false)
    const [showExportDate, setShowExportDate] = useState(false)
    const [exportModal, setExportModal] = useState(false)


    useEffect(() => {
        // GraphData();
        getDashboardDetail()
    }, []);

    const openFilter = (Action) => {
        setFilterDetail(true);
    };

    const closeFilter = () => {
        setFilterDetail(false);
    };

    const handleFilterSelectChange = (event) => {
        setFilterSelectValue(event?.target?.value)
    }


    const getDashboardDetail = async () => {
        await getMethod(urls.dashboard + getStoreId() + "/").then((response) => {
            if (response.status === 200 || response.status === 201) {
                setAllDashboardData(response?.data?.response)
            }
        })
    }

    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    })

    const customStyles = {
        content: {
            top: "40%",
            left: "50%",
            transform: screenSize.width <= 800 ? 'translate(-20%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            // padding: "15px",
            width: screenSize.width <= 800 ? "40vw" : "38vw",
            height: screenSize.width <= 800 ? "15vh" : "12vh",
        },
    };

    const allStyless = {
        content: {
            top: "50%",
            left: "75%",
            transform: screenSize.width <= 1100 ? 'translate(-60%, -50%)' : 'translate(-42%, -50%)',
            backgroundColor: "white",
            border: "1px #ccc",
            borderRadius: "4px",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            outline: "none",
            padding: "15px",
            width: screenSize.width <= 1100 ? "35vw" : "25vw",
            height: "35vh",
        },
    };


    const GraphData = async () => {
        await getMethod(urls.getDashboardDetail).then((response) => {
            setAllDashboardData(response?.data?.response);
            setAverageSalesofItems(response?.data?.response?.averageSalesofItems);
            setAverageSalesofItemsGraphData(response?.data?.response?.averageSalesofItems?.graphData?.length > 0 && response?.data?.response?.averageSalesofItems?.graphData?.sort((a, b) => new Date(...a.date.split('/').reverse()) - new Date(...b.date.split('/').reverse())));
            setReturns(response?.data?.response?.returns);
            setReturnsGraphData(response?.data?.response?.returns?.graphData?.length > 0 && response?.data?.response?.returns?.graphData?.sort((a, b) => new Date(...a.date.split('/').reverse()) - new Date(...b.date.split('/').reverse())));
            setSalesTax(response?.data?.response?.salesTax);
            setSalesGraphData(response?.data?.response?.salesTax?.graphData?.length > 0 && response?.data?.response?.salesTax?.graphData?.sort((a, b) => new Date(...a.date.split('/').reverse()) - new Date(...b.date.split('/').reverse())));
            setTransaction(response?.data?.response?.transaction);
            setTransactionGraphData(response?.data?.response?.transaction?.graphData?.length > 0 && response?.data?.response?.transaction?.graphData?.sort((a, b) => new Date(...a.date.split('/').reverse()) - new Date(...b.date.split('/').reverse())));
            setGrossSales(response?.data?.response?.grossSales);
            setGrossSalesGraphData(response?.data?.response?.grossSales?.graphData?.length > 0 && response?.data?.response?.grossSales?.graphData?.sort((a, b) => new Date(...a.date.split('/').reverse()) - new Date(...b.date.split('/').reverse())));
            setNetSales(response?.data?.response?.netSales);
            setNetSalesGraphData(response?.data?.response?.netSales?.graphData?.length > 0 && response?.data?.response?.netSales?.graphData?.sort((a, b) => new Date(...a.date.split('/').reverse()) - new Date(...b.date.split('/').reverse())));
            setAverageSalesValue(response?.data?.response?.averageSalesValue);
            setAverageSalesValueGraphData(response?.data?.response?.averageSalesValue?.graphData?.length > 0 && response?.data?.response?.averageSalesValue?.graphData?.sort((a, b) => new Date(...a.date.split('/').reverse()) - new Date(...b.date.split('/').reverse())));
            setGrossprofit(response?.data?.response?.grossprofit);
            setGrossprofitGraphData(response?.data?.response?.grossprofit?.graphData?.length > 0 && response?.data?.response?.grossprofit?.graphData?.sort((a, b) => new Date(...a.date.split('/').reverse()) - new Date(...b.date.split('/').reverse())));
            setGrossMargin(response?.data?.response?.grossMargin);
            setGrossMarginGraphData(response?.data?.response?.grossMargin?.graphData?.length > 0 && response?.data?.response?.grossMargin?.graphData?.sort((a, b) => new Date(...a.date.split('/').reverse()) - new Date(...b.date.split('/').reverse())));
            setGrossMarkup(response?.data?.response?.grossMarkup);
            setGrossMarkupGraphData(response?.data?.response?.grossMarkup?.graphData?.length > 0 && response?.data?.response?.grossMarkup?.graphData?.sort((a, b) => new Date(...a.date.split('/').reverse()) - new Date(...b.date.split('/').reverse())));
            setCostOfGood(response?.data?.response?.costOfGood);
            setCostOfGoodGraphData(response?.data?.response?.costOfGood?.graphData?.length > 0 && response?.data?.response?.costOfGood?.graphData.sort((a, b) => new Date(...a.date.split('/').reverse()) - new Date(...b.date.split('/').reverse())));

        }).catch((error) => {
            console.log(error);
        });
    }

    // const handleDashboardListFilter = async (value) => {
    //     await getMethod(urls.dashboard + getStoreId() + "?" + "start_date:" + startDate + "&" + "end_date" + endDate + (value !== "" ? `& ${value}=1` : "")).then((response) => {
    //         if (response.status === 200 || response.status === 201) {
    //             setAllDashboardData(response?.data?.response)
    //         }
    //     })
    // }

    const handleDashboardListFilter = async (value) => {
        const params = [];

        if (value === "custom") {
            params.push(`start_date=${format(startDate, 'yyyy-MM-dd')}`, `end_date=${format(endDate, 'yyyy-MM-dd')}`);
        }

        if (value && value !== "custom") {
            params.push(`${value}=1`);
        }

        const finalUrl = `${urls.dashboard}${getStoreId()}?${params.join("&")}`;

        try {
            const response = await getMethod(finalUrl);
            if (response.status === 200 || response.status === 201) {
                setAllDashboardData(response?.data?.response);
                setFilterDetail(false);
                setShowFilterDate(true)
            }
        } catch (error) {
            console.error("Error fetching dashboard data:", error);
        }
    };

    const today = new Date();
    const formattedDate = today.toLocaleDateString('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
    });

    const openExportData = () => {
        setExportModal(true)
    }

    const closeExport = () => {
        setExportModal(false)
    }

    const handleExportData = async () => {
        try {
            const response = await getMethod(urls.exportData + "?start_date=" + exportStartDate + "&end_date=" + exportEndDate);

            if (response.status === 200 || response.status === 201) {
                const data = response.data?.response;
                setExportModal(false)
                setExportShowStartDate(response?.data?.start_date)
                setExportShowEndDate(response?.data?.end_date)
                setShowExportDate(true)

                if (!Array.isArray(data) || data.length === 0) {
                    console.error("No data available to export");
                    return;
                }

                const headers = Object.keys(data[0]).join(",");

                const rows = data.map(row =>
                    Object.keys(row)
                        .map(key => row[key])
                        .join(",")
                ).join("\n");

                const csvContent = headers + "\n" + rows;
                const blob = new Blob([csvContent], { type: "text/csv" });
                const url = URL.createObjectURL(blob);

                const link = document.createElement("a");
                link.href = url;
                link.download = "export sells report.csv";
                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                URL.revokeObjectURL(url);
                toast.success('CSV file exported and downloaded successfully')
            }
        } catch (error) {
            console.error("Error exporting data:", error);
        }
    };

    const handleChangeDate = (date) => {
        setStartDate(date);
    }

    const handleEndChangeDate = (date) => {
        setEndDate(date)
    }


    return (
        <>
            <div className={props.menu ? "screenLeftMargin w-100 mt-3 home_setting" : "screenLeftMarginResponse w-100 mt-3 home_setting"}>
                <div className="ml-4 mobileResponsive">
                    <div className="d-flex justify-content-between">
                        <div><h1 className="heading-title">Dashboard</h1></div>
                        <div className="d-flex mr-5">
                            <div>
                                <h4 className="dashboard-date mt-1 mr-3">{showFilterDate ? `${moment(startDate).format('MMM D, YYYY')} - ${moment(endDate).format('MMM D, YYYY')}` : formattedDate}</h4>
                            </div>
                            <div onClick={() => openFilter()} style={{ cursor: "pointer" }}>
                                <img src={FilterDb} />
                                <span className="filter-text ml-2">Filter</span>
                            </div>
                            {/* <div className="ml-2" onClick={() => openExportData()} style={{ cursor: "pointer" }}>
                                <img src={ExportIcon} />
                                <span className="filter-text ml-1">Export Sells Report</span>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className="row ml-3 mr-3 pl-3 pr-3 mt-3 mobileResponsive">
                    <div className="col-sm-4 col-md-4 col-lg-3 col-3">
                        <div className="dsWhiteBox pl-3 pr-3 pt-3 pb-3">
                            <div className="box-title">Gross Sales</div>
                            <div className="box-total mt-4">${allDashboardData?.gross_sales?.toFixed(2)}
                                {/* <span className={grossSales?.deviation === "negative" ? "box-down-percentage" : "box-up-percentage"}>{grossSales?.deviation === "negative" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}{grossSales?.checkDeviationAmount?.toFixed(2)}%</span> */}
                            </div>
                            <div className="box-chart mt-4 row">
                                {/* <AreaChart width={230} className="col-sm-12" height={100} data={grossSalesGraphData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                    <defs>
                                        <linearGradient id="PRv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#F13636" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#F9A9A9" stopOpacity={0} />
                                        </linearGradient>
                                        <linearGradient id="Pv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#12872C" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#A9F4BA" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                    <XAxis dataKey="date" height={0} tick={false} />
                                    <Tooltip />
                                    <Area type="linear" dataKey="grossSales" stroke={grossSales?.deviation === "negative" ? "#F13636" : "#12872C"} fillOpacity={1} fill={grossSales?.deviation === "negative" ? "url(#PRv)" : "url(#Pv)"} />
                                </AreaChart> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-4 col-md-4 col-lg-3 col-3">
                        <div className="dsWhiteBox pl-3 pr-3 pt-3 pb-3">
                            <div className="box-title">Returns</div>
                            <div className="box-total mt-4">${allDashboardData?.return_sales?.toFixed(2)}
                                {/* <span className={returns?.deviation === "negative" ? "box-down-percentage" : "box-up-percentage"}>{returns?.deviation === "negative" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}{returns?.checkDeviationAmount?.toFixed(2)}%</span> */}
                            </div>
                            <div className="box-chart mt-4 row">
                                {/* <AreaChart width={230} className="col-sm-12" height={100} data={returnsGraphData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                    <defs>
                                        <linearGradient id="PRv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#F13636" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#F9A9A9" stopOpacity={0} />
                                        </linearGradient>
                                        <linearGradient id="Pv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#12872C" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#A9F4BA" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                    <XAxis dataKey="date" height={0} tick={false} />
                                    <Tooltip />
                                    <Area type="linear" dataKey="return" stroke={returns?.deviation === "negative" ? "#F13636" : "#12872C"} fillOpacity={1} fill={returns?.deviation === "negative" ? "url(#PRv)" : "url(#Pv)"} />
                                </AreaChart> */}
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-sm-6 col-md-6 col-lg-2">
                    <div className="dsWhiteBox pl-3 pr-3 pt-3 pb-3">
                        <div className="box-title">Discounts</div>
                        <div className="box-total mt-4">$10.00 <span className="box-up-percentage"><ArrowDropUpIcon /> 100%</span></div>
                        <div className="box-chart mt-4 row">
                            <AreaChart width={230} className="col-sm-12" height={100} data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                <defs>
                                    <linearGradient id="PRv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#F13636" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#F9A9A9" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="Pv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#12872C" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#A9F4BA" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="name" height={0} tick={false}>
                                </XAxis>
                                <Tooltip />
                                <Area type="linear" dataKey="pv" stroke="#12872C" fillOpacity={1} fill="url(#Pv)" />
                            </AreaChart>
                        </div>
                    </div>
                </div> */}


                    <div className="col-sm-4 col-md-4 col-lg-3 col-3">
                        <div className="dsWhiteBox pl-3 pr-3 pt-3 pb-3">
                            <div className="box-title">Sales Tax</div>
                            <div className="box-total mt-4">${allDashboardData?.net_tax?.toFixed(2)}
                                {/* <span className={salesTax?.deviation === "negative" ? "box-down-percentage" : "box-up-percentage"}>{salesTax?.deviation === "negative" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}{salesTax?.checkDeviationAmount?.toFixed(2)}%</span> */}
                            </div>
                            <div className="box-chart mt-4 row">
                                {/* <AreaChart width={230} className="col-sm-12" height={100} data={salesGraphData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                    <defs>
                                        <linearGradient id="PRv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#F13636" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#F9A9A9" stopOpacity={0} />
                                        </linearGradient>
                                        <linearGradient id="Pv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#12872C" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#A9F4BA" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                    <XAxis dataKey="date" height={0} tick={false} />
                                    <Tooltip />
                                    <Area type="linear" dataKey="sales" stroke={salesTax?.deviation === "negative" ? "#F13636" : "#12872C"} fillOpacity={1} fill={salesTax?.deviation === "negative" ? "url(#PRv)" : "url(#Pv)"} />
                                </AreaChart> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-4 col-md-4 col-lg-3 col-3">
                        <div className="dsDarkBox pl-3 pr-3 pt-3 pb-3">
                            <div className="box-title">Net Sales</div>
                            <div className="box-total mt-4">${allDashboardData?.net_sales?.toFixed(2)}
                                {/* <span className={netSales?.deviation === "negative" ? "box-down-percentage" : "box-up-percentage"}>{netSales?.deviation === "negative" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}{netSales?.checkDeviationAmount?.toFixed(2)}%</span> */}
                            </div>
                            <div className="box-chart mt-4 row">
                                {/* <AreaChart width={230} className="col-sm-12" height={100} data={netSalesGraphData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                    <defs>
                                        <linearGradient id="PRv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#F13636" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#F9A9A9" stopOpacity={0} />
                                        </linearGradient>
                                        <linearGradient id="Pv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#12872C" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#A9F4BA" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                    <XAxis dataKey="date" height={0} tick={false} />
                                    <Tooltip />
                                    <Area type="linear" dataKey="netSales" stroke={netSales?.deviation === "negative" ? "#F13636" : "#12872C"} fillOpacity={1} fill={netSales?.deviation === "negative" ? "url(#PRv)" : "url(#Pv)"} />
                                </AreaChart> */}
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row ml-3 mr-3 pl-3 pr-3 mt-4 mobileResponsive">

                    <div className="col-sm-4 col-md-6 col-lg-4">
                        <div className="dsWhiteBox pl-3 pr-3 pt-3 pb-3">
                            <div className="box-title">Transactions</div>
                            <div className="box-total mt-4">{(allDashboardData?.number_of_transactions + allDashboardData?.number_of_returns)}
                                {/* <span className={transaction?.deviation === "negative" ? "box-down-percentage" : "box-up-percentage"}>{transaction?.deviation === "negative" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}{transaction?.checkDeviationAmount?.toFixed(2)}%</span> */}
                            </div>
                            <div className="box-chart mt-4 row">
                                {/* <AreaChart width={490} className="col-sm-12" height={100} data={transactionGraphData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                    <defs>
                                        <linearGradient id="PRv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#F13636" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#F9A9A9" stopOpacity={0} />
                                        </linearGradient>
                                        <linearGradient id="Pv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#12872C" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#A9F4BA" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                    <XAxis dataKey="date" height={0} tick={false} />
                                    <Tooltip />
                                    <Area type="linear" dataKey="transcation" stroke={transaction?.deviation === "negative" ? "#F13636" : "#12872C"} fillOpacity={1} fill={transaction?.deviation === "negative" ? "url(#PRv)" : "url(#Pv)"} />
                                </AreaChart> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-4 col-md-6 col-lg-4 col-4">
                        <div className="dsWhiteBox pl-3 pr-3 pt-3 pb-3">
                            <div className="box-title">Average Sale Value</div>
                            <div className="box-total mt-4">${allDashboardData?.average_sales_value?.toFixed(2)}
                                {/* <span className={averageSalesValue?.deviation === "negative" ? "box-down-percentage" : "box-up-percentage"}>{averageSalesValue?.deviation === "negative" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}{averageSalesValue?.checkDeviationAmount?.toFixed(2)}%</span> */}
                            </div>
                            <div className="box-chart mt-4 row">
                                {/* <AreaChart className="col-sm-12" width={490} height={100} data={averageSalesValueGraphData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                    <defs>
                                        <linearGradient id="PRv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#F13636" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#F9A9A9" stopOpacity={0} />
                                        </linearGradient>
                                        <linearGradient id="Pv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#12872C" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#A9F4BA" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                    <XAxis dataKey="date" height={0} tick={false} />
                                    <Tooltip />
                                    <Area type="linear" dataKey="averageSaleValue" stroke={averageSalesValue?.deviation === "negative" ? "#F13636" : "#12872C"} fillOpacity={1} fill={averageSalesValue?.deviation === "negative" ? "url(#PRv)" : "url(#Pv)"} />
                                </AreaChart> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-md-6 col-lg-4 col-4">
                        <div className="dsWhiteBox pl-3 pr-3 pt-3 pb-3">
                            <div className="box-title">Average Item per Sale</div>
                            <div className="box-total mt-4">{allDashboardData?.average_items_per_sale?.toFixed(2)}
                                {/* <span className={averageSalesofItems?.deviation === "negative" ? "box-down-percentage" : "box-up-percentage"}>{averageSalesofItems?.deviation === "negative" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}{averageSalesofItems?.checkDeviationAmount?.toFixed(2)}%</span> */}
                            </div>
                            <div className="box-chart mt-4 row">
                                {/* <AreaChart className="col-sm-12" width={490} height={100} data={averageSalesofItemsGraphData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                    <defs>
                                        <linearGradient id="PRv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#F13636" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#F9A9A9" stopOpacity={0} />
                                        </linearGradient>
                                        <linearGradient id="Pv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#12872C" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#A9F4BA" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                    <XAxis dataKey="date" height={0} tick={false} />
                                    <Tooltip />
                                    <Area type="linear" dataKey="averageItems" stroke={averageSalesofItems?.deviation === "negative" ? "#F13636" : "#12872C"} fillOpacity={1} fill={averageSalesofItems?.deviation === "negative" ? "url(#PRv)" : "url(#Pv)"} />
                                </AreaChart> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row ml-3 mr-3 pl-3 pr-3 mt-4 mobileResponsive">

                    <div className="col-sm-4 col-md-4 col-lg-3 col-3">
                        <div className="dsWhiteBox pl-3 pr-3 pt-3 pb-3">
                            <div className="box-title">Net COGS</div>
                            <div className="box-total mt-4">${allDashboardData?.net_cogs?.toFixed(2)}
                                {/* <span className={costOfGood?.checkDeviationSign === "negative" ? "box-down-percentage" : "box-up-percentage"}>{costOfGood?.checkDeviationSign === "negative" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}{costOfGood?.checkDeviationAmount?.toFixed(2)}%</span> */}
                            </div>
                            <div className="box-chart mt-4 row">
                                {/* <AreaChart width={230} className="col-sm-12" height={100} data={costOfGoodGraphData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                    <defs>
                                        <linearGradient id="PRv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#F13636" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#F9A9A9" stopOpacity={0} />
                                        </linearGradient>
                                        <linearGradient id="Pv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#12872C" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#A9F4BA" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                    <XAxis dataKey="date" height={0} tick={false} />
                                    <Tooltip />
                                    <Area type="linear" dataKey="costofGood" stroke={costOfGood?.checkDeviationSign === "negative" ? "#F13636" : "#12872C"} fillOpacity={1} fill={costOfGood?.checkDeviationSign === "negative" ? "url(#PRv)" : "url(#Pv)"} />
                                </AreaChart> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-4 col-md-4 col-lg-3 col-3">
                        <div className="dsWhiteBox pl-3 pr-3 pt-3 pb-3">
                            <div className="box-title">Gross Profit</div>
                            <div className="box-total mt-4">${allDashboardData?.gross_profit?.toFixed(2)}
                                {/* <span className={grossprofit?.checkDeviationSign === "negative" ? "box-down-percentage" : "box-up-percentage"}>{grossprofit?.checkDeviationSign === "negative" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}{grossprofit?.checkDeviationAmount?.toFixed(2)}%</span> */}
                            </div>
                            <div className="box-chart mt-4 row">
                                {/* <AreaChart width={230} className="col-sm-12" height={100} data={grossprofitGraphData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                    <defs>
                                        <linearGradient id="PRv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#F13636" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#F9A9A9" stopOpacity={0} />
                                        </linearGradient>
                                        <linearGradient id="Pv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#12872C" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#A9F4BA" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                    <XAxis dataKey="date" height={0} tick={false} />
                                    <Tooltip />
                                    <Area type="linear" dataKey="grossProfit" stroke={grossprofit?.checkDeviationSign === "negative" ? "#F13636" : "#12872C"} fillOpacity={1} fill={grossprofit?.checkDeviationSign === "negative" ? "url(#PRv)" : "url(#Pv)"} />
                                </AreaChart> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-4 col-md-4 col-lg-3 col-3">
                        <div className="dsWhiteBox pl-3 pr-3 pt-3 pb-3">
                            <div className="box-title">Gross Markup</div>
                            <div className="box-total mt-4">{allDashboardData?.gross_markup?.toFixed(2)}%
                                {/* <span className={grossMarkup?.checkDeviationSign === "negative" ? "box-down-percentage" : "box-up-percentage"}>{grossMarkup?.checkDeviationSign === "negative" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}{grossMarkup?.checkDeviationAmount?.toFixed(2)}%</span> */}
                            </div>
                            <div className="box-chart mt-4 row">
                                {/* <AreaChart width={230} className="col-sm-12" height={100} data={grossMarkupGraphData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                    <defs>
                                        <linearGradient id="PRv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#F13636" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#F9A9A9" stopOpacity={0} />
                                        </linearGradient>
                                        <linearGradient id="Pv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#12872C" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#A9F4BA" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                    <XAxis dataKey="date" height={0} tick={false} />
                                    <Tooltip />
                                    <Area type="linear" dataKey="grossMarkup" stroke={grossMarkup?.checkDeviationSign === "negative" ? "#F13636" : "#12872C"} fillOpacity={1} fill={grossMarkup?.checkDeviationSign === "negative" ? "url(#PRv)" : "url(#Pv)"} />
                                </AreaChart> */}
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-4 col-md-4 col-lg-3 col-3">
                        <div className="dsWhiteBox pl-3 pr-3 pt-3 pb-3">
                            <div className="box-title">Gross Margin</div>
                            <div className="box-total mt-4">{allDashboardData?.gross_margin?.toFixed(2)}%
                                {/* <span className={grossMargin?.checkDeviationSign === "negative" ? "box-down-percentage" : "box-up-percentage"}>{grossMargin?.checkDeviationSign === "negative" ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}{grossMargin?.checkDeviationAmount?.toFixed(2)}%</span> */}
                            </div>
                            <div className="box-chart mt-4 row">
                                {/* <AreaChart width={230} className="col-sm-12" height={100} data={grossMarginGraphData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                    <defs>
                                        <linearGradient id="PRv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#F13636" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#F9A9A9" stopOpacity={0} />
                                        </linearGradient>
                                        <linearGradient id="Pv" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#12872C" stopOpacity={0.8} />
                                            <stop offset="95%" stopColor="#A9F4BA" stopOpacity={0} />
                                        </linearGradient>
                                    </defs>
                                    <XAxis dataKey="date" height={0} tick={false} />
                                    <Tooltip />
                                    <Area type="linear" dataKey="grossMargin" stroke={grossMargin?.checkDeviationSign === "negative" ? "#F13636" : "#12872C"} fillOpacity={1} fill={grossMargin?.checkDeviationSign === "negative" ? "url(#PRv)" : "url(#Pv)"} />
                                </AreaChart> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <Modal isOpen={filterDetail} style={allStyless} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="row">
                    <div className="col-6" >
                        <img src={FilterDb} />
                        <span className="filter-text ml-2">Filter by</span>
                    </div>
                    <div className="col-6" style={{ textAlign: "right" }}>
                        <button onClick={() => closeFilter()} className="filterCloseIcon">
                            <CloseIcon style={{ fontSize: "18px" }} />
                        </button>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-12">
                        <FormControl variant="outlined" className="gray-select">
                            <InputLabel
                                required
                                id="status-select"
                            >
                                Status
                            </InputLabel>
                            <Select
                                labelId="status-select"
                                id="status-dropdown"
                                value={filterSelectValue}
                                onChange={(e) => handleFilterSelectChange(e)}
                                label="Status"
                                name="status"
                            >
                                <MenuItem value={"week"}> Week </MenuItem>
                                <MenuItem value={"month"}> Month </MenuItem>
                                <MenuItem value={"year"}> Year </MenuItem>
                                <MenuItem value={"custom"}> Custom </MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>


                <div className="row">
                    <div className="col-6">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                variant="outlined"
                                format="yyyy-MM-dd"
                                margin="normal"
                                id="date-picker-inline"
                                label="Start Date"
                                className={startDate !== "" ? "input-datepicker selectedDate" : "input-datepicker"}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                InputProps={{ disableUnderline: true }}
                                value={startDate ? format(startDate, 'yyyy MM dd') : ''}
                                onChange={(e) => { handleChangeDate(e) }}
                                error={false}
                                helperText={null}
                                disabled={filterSelectValue !== "custom"}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-6">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                variant="outlined"
                                format="yyyy-MM-dd"
                                margin="normal"
                                id="date-picker-inline"
                                label="End Date"
                                className={endDate !== "" ? "input-datepicker selectedDate" : "input-datepicker"}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                InputProps={{ disableUnderline: true }}
                                value={endDate ? format(endDate, 'yyyy MM dd') : ''}
                                onChange={(e) => handleEndChangeDate(e)}
                                error={false}
                                helperText={null}
                                disabled={filterSelectValue !== "custom"}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <hr className="filter-hr w-100 mt-3 mr-3" />
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 mt-3" style={{ textAlign: "center" }}>
                        <Button className="btn btn-primary" name="Filter" onClick={() => handleDashboardListFilter(filterSelectValue)}></Button>
                    </div>
                </div>
            </Modal>

            {/* <Modal isOpen={exportModal} style={allStyless} overlayClassName="myoverlay" closeTimeoutMS={500}>
                <div className="row">
                    <div className="col-6" >
                        <span className="filter-text ml-2">Export Sells Report</span>
                    </div>
                    <div className="col-6" style={{ textAlign: "right" }}>
                        <button onClick={() => closeExport()} className="filterCloseIcon">
                            <CloseIcon style={{ fontSize: "18px" }} />
                        </button>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-6">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                variant="outlined"
                                format="yyyy-MM-dd"
                                margin="normal"
                                id="date-picker-inline"
                                label="Start Date"
                                className={exportStartDate !== "" ? "input-datepicker selectedDate" : "input-datepicker"}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                InputProps={{ disableUnderline: true }}
                                value={exportStartDate}
                                onChange={(e) => {
                                    setExportStartDate(moment(e).format('YYYY-MM-DD'))
                                }}
                                error={false}
                                helperText={null}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-6">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                variant="outlined"
                                format="yyyy-MM-dd"
                                margin="normal"
                                id="date-picker-inline"
                                label="End Date"
                                className={exportEndDate !== "" ? "input-datepicker selectedDate" : "input-datepicker"}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                InputProps={{ disableUnderline: true }}
                                value={exportEndDate ? exportEndDate : ""}
                                onChange={(e) => {
                                    setExportEndDate(moment(e).format('YYYY-MM-DD'))
                                }}
                                error={false}
                                helperText={null}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 mt-3" style={{ textAlign: "center" }}>
                        <Button className="btn btn-primary" name="Export" onClick={() => handleExportData()}></Button>
                    </div>
                </div>
            </Modal> */}
        </>
    )
}
export default Home;